import LandingPageLayout from "../../components/landing-page/LandingPageLayout";
import { FooterSection } from "./components";
import "./styling/agent.scss";

function AgentsUsepolicy() {
  return (
    <LandingPageLayout inverse={true}>
      <section className="privacy-policy-container">
        {/* <h1>Acceptable Use Policy</h1> */}

        <section className="rider-terms-section">
          <h1> RIDER REGISTRATION TERMS AND CONDITIONS/CONTRACT</h1>
          <p>
            <strong>Effective Date:</strong> Nov 2023
          </p>

          <p>Welcome to Peakreach!</p>
          <p>
            We're excited to have you join our team of dedicated delivery
            agents. This Peakreach Agent's Contract outlines the terms and
            conditions of your engagement with our platform. By agreeing to this
            contract, you are embarking on an exciting journey as a delivery
            partner, helping us connect people with the products and services
            they need while enjoying the flexibility and opportunities that come
            with it.
          </p>
          <p>
            Kindly read this contract thoroughly to understand your rights and
            responsibilities as a delivery agent. At Peakreach, we value your
            contribution and are fully devoted to giving a rewarding, safe, and
            mutually beneficial experience.
          </p>

          <h2>Agreement:</h2>

          <h3>1. Rider Registration</h3>
          <p>
            By registering as a rider with Peakreach, the Rider agrees to abide
            by the terms and conditions outlined in this Agreement.
          </p>

          <h3>2. Requirements</h3>
          <p>
            <strong>2.1.</strong> At PearReach, a rider must be at least 18
            years of age to register as a delivery agent for Peakreach.
          </p>
          <p>
            <strong>2.2.</strong> Riders are required to have a reliable
            vehicle, smartphone, and mobile data for the Peakreach platform
            usage.
          </p>

          <h3>3. Independent Contractor Status</h3>
          <p>
            <strong>3.1.</strong> Rider acknowledges that they are registering
            as an independent contractor and not as an employee of the Company.
            As such, Riders are fully responsible for fueling and maintenance of
            their bikes.
          </p>
          <p>
            <strong>3.2.</strong> Rider also acknowledges that they are
            responsible for their own taxes and use benefits.
          </p>

          <h3>4. Branded Merchandise</h3>
          <p>
            <strong>4.1.</strong> Riders are given Peakreach brand items after
            successful registration on the platform which the rider agrees to
            always have on during deliveries.
          </p>
          <p>
            <strong>4.2.</strong> After the Platform's merchandise are issued,
            the rider is expected to use the items as deemed fit & instructed by
            Peakreach and in any case of return, should be returned in good
            condition.
          </p>

          <h3>5. Services</h3>
          <p>
            <strong>5.1.</strong> Rider agrees to provide delivery services to
            registered partners as directed by the Peakreach app.
          </p>
          <p>
            <strong>5.2.</strong> Rider also agrees to provide dispatch services
            to customers on the Peakreach platform seeking to deliver items from
            one location to another.
          </p>

          <h3>6. Payment</h3>
          <p>
            <strong>6.1.</strong> Rider's earnings are made accessible for
            withdrawal on a weekly basis, however they are able to monitor their
            earnings via the wallet system (Reachwallet) made available to them
            on the platform.
          </p>

          <h3>7. Compensation</h3>
          <p>
            <strong>7.1.</strong> Rider's compensation is as outlined in a
            separate compensation agreement or as mutually agreed upon.
          </p>

          <h3>8. Confidentiality</h3>
          <p>
            <strong>8.1.</strong> Rider shall maintain the confidentiality of
            all Company-related information, including customer information and
            trade secrets.
          </p>

          <h3>9. Termination</h3>
          <p>
            <strong>9.1.</strong> Either party may terminate this Agreement at
            any time, with or without notice.
          </p>

          <h3>10. Governing Law and Jurisdiction</h3>
          <p>
            <strong>10.1.</strong> This Agreement is governed by and constructed
            in accordance with the laws of six local governments in Abuja. Any
            disputes arising from or related to this Agreement shall be subject
            to the exclusive jurisdiction of the courts in all six local
            governments in Abuja.
          </p>

          <h3>11. Entire Agreement</h3>
          <p>
            <strong>11.1.</strong> This Agreement constitutes the entire
            agreement between the parties and supersedes all prior
            understandings and agreements.
          </p>

          <h3>12. Amendments</h3>
          <p>
            <strong>12.1.</strong> Any amendments to this Agreement must be in
            writing and signed by both parties.
          </p>

          <p>
            Thank you for choosing to be a part of the Peakreach community. We
            look forward to working together to provide efficient and reliable
            delivery services to our customers while supporting your growth and
            success as a rider.
          </p>
        </section>
      </section>
      <FooterSection />
    </LandingPageLayout>
  );
}

export default AgentsUsepolicy;

import "../styling/about-us.scss";
import aboutImg from "../../../assets/images/landing-page/about-img.svg";
import { useEffect } from "react";
// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css';

function AboutUs() {

    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <section id="about" className="about-us" >
            <div>
                <h2 >ABOUT US</h2>
                <div className="grid-container">
                    <div className="img">
                        <img src={aboutImg} alt="about" />
                    </div>
                    <div className="text">
                        <p>At Peakreach, our journey began with a simple but profound idea: to provide essential services that make life easier and businesses more efficient. We believe in bridging the gap between everyday needs and reliable logistics, ensuring that no distance is too far, and no task is too complex.
                        </p>
                        <p>Our mission is to create a seamless experience for individuals and businesses, whether it's delivering a hot meal right to your doorstep or streamlining logistics for companies.</p>
                        <p>We are guided by a set of core values that underpin our commitment to excellence: Reliability, Speed, Innovation, and Customer Satisfaction. These values shape our approach to every service we provide.</p>
                        <p>We put you at the heart of everything we do. From individuals looking for a quick meal to businesses seeking streamlined logistics, our focus is on ensuring your satisfaction. We are constantly evolving, embracing the latest technologies to enhance our services. Our easy-to-use app, same-day delivery, and other innovative solutions reflect our commitment to modern convenience.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default AboutUs
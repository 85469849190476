import React, { useState } from "react";
import "../styling/faq.scss";

interface FAQItem {
  question: string;
  answer: string;
}

const restaurantFaq = [
  {
    question: "What is Peakreach?",
    answer:
      "Peakreach is a food delivery platform that connects local restaurants with customers for online ordering and delivery, expanding your customer reach and increasing your sales.",
  },
  {
    question: "How do I register my restaurant on the platform?",
    answer:
      "Visit our registration page here, fill out the required information, and submit the necessary documents. Our team will review your application and get back to you within 2-5 business days.",
  },
  {
    question: "How do I log in to my restaurant account?",
    answer:
      "Visit our website, click “get started”, scroll to the restaurant portal section and go to app. You will be re-directed to login page where you can type in your registered business email and password.",
  },
  {
    question: "Can I update my restaurant information after registration?",
    answer:
      "Yes, you can update your menu, prices, restaurant details, and more by logging into your restaurant dashboard and navigating to the profile page.",
  },
  {
    question: "How do I add or remove menu items?",
    answer:
      'On the Peakreach merchant website, go to the menu section, and use the "Add Item" or "Remove Item" buttons to manage your offerings.',
  },
  {
    question: "How do I receive and confirm orders?",
    answer:
      "Orders will be sent to your restaurant dashboard in real-time. Confirm the order within the stipulated time to ensure timely delivery.",
  },
  {
    question: "What if I need to cancel an order?",
    answer:
      "In case of an unavoidable situation requiring order cancellation, there is a “cancel” option on every incoming order as well as an “accept” option, click to select an option. Every cancel action must have a valid reason.",
  },
  {
    question: "How do I handle special requests or allergies?",
    answer:
      "Special requests will be visible with the order details. Make sure to review them carefully and accommodate them if possible. For allergies, ensure clear communication and adherence to safety protocols.",
  },
  {
    question: "How and when do I get paid?",
    answer:
      "Payments are processed daily. Your earnings, minus our commission, will be directly deposited into the bank account provided during registration.",
  },
  {
    question: "What is the commission rate?",
    answer:
      "The commission rate is a percentage of each order total and varies based on your contract. Detailed information can be found in your agreement or by contacting our support team.",
  },
  {
    question: "Are there any other fees?",
    answer:
      "Additional fees may include marketing fees, equipment fees (if any), and service fees. Refer to your agreement for a detailed breakdown.",
  },
  {
    question: "How do I contact customer support?",
    answer:
      "You can reach our customer support team via email, phone, or live chat through the restaurant dashboard. Support is available 24/7 for urgent issues.",
  },
  {
    question:
      "What should I do if I encounter technical issues with the platform?",
    answer:
      "For technical issues, first try reloading the web page, checking your internet connection and (on rare occasions), restarting your device. If the problem persists, contact our technical support team through the help section in your dashboard.",
  },
  {
    question: "How can I give feedback about the platform?",
    answer:
      "We value your feedback. Use the contact us on the  landing page or contact our support team directly to share your suggestions or concerns.",
  },
  {
    question: "How can I participate in promotions?",
    answer:
      "We offer various promotional opportunities, such as featured listings and special discounts. Contact your account manager or the Peakreach marketing team to learn more about current promotions and how to participate.",
  },
  {
    question: "Can I use my own marketing materials?",
    answer:
      "Yes, you can promote your presence on our platform through your own channels. We also provide marketing resources and guidelines to help you effectively market your restaurant.",
  },
  {
    question: "How do I handle customer reviews and ratings?",
    answer:
      "Customer feedback is important. Respond to reviews professionally through the dashboard. Positive reviews can be highlighted, and negative feedback should be addressed constructively to improve your service.",
  },
];

const customerFaq = [
  {
    question: "How do I create an account?",
    answer:
      'To create an account, click on the "Sign Up" button on the home page, enter your email address, phone number, create a password, and follow the prompts to complete your profile.',
  },
  {
    question: "How do I place an order?",
    answer:
      'After logging in, browse through the available restaurants, select your items, and click on "Add to Cart." When you\'re ready, go to your cart, review your order, and click "Checkout" to place your order.',
  },
  {
    question: "Which locations is Peakreach available in?",
    answer:
      "Peakreach is currently located in Abuja and can complete delivery to all locations within the state.",
  },
  {
    question: "How do I track my order?",
    answer:
      "Once you place an order, the order will appear in the “Active Orders” section on your home screen from which you can tap on to see the order details and current status of the order. You’ll receive notification updates as your order is prepared, picked up, and delivered. You can also go to the “Orders” section on the accounts screen and select the order to see its status.",
  },
  {
    question: "What if my order is late?",
    answer:
      "If your order is delayed, You can also contact our customer support from the support section, in the accounts screen, for further assistance on actions to be taken.",
  },
  {
    question: "Can I cancel or change my order?",
    answer:
      "You cannot cancel your order after placement from your end after placement. If a need for cancellation arises, please contact customer support to assist with the cancellation and refund.",
  },
  {
    question: "What payment methods are accepted?",
    answer: "We currently only accept card and cash payments.",
  },
  {
    question: "How do I use a promo code?",
    answer:
      'During the checkout process, enter your promo code in the designated field and click "Apply." The discount will be reflected in your order total. WE DON\'T HAVE PROMO CODE YET.',
  },
  {
    question: "How do I request a refund?",
    answer:
      "If there is an issue with your order, please contact our customer care center. Provide details about the issue, and our team will review your request.",
  },
  {
    question: "How do I reset my password?",
    answer:
      'If you’ve forgotten your password, click on "Forgot Password" on the login page, enter your registered email. A code will be sent to the email. Input the code in the app to verify your email. After verification you will be navigated to the password reset section where you can rest your password.',
  },
  {
    question: "How do I update my delivery address?",
    answer:
      "The delivery address is set to your current location or the location of your last active order if you have any. To change this location, tap on the location icon on the home screen. You will be navigated to the location screen. Search and choose your new delivery location.",
  },
  {
    question: "How do I contact customer support?",
    answer:
      'You can contact our customer support through the "Help" section in the app. You can also reach us via email at support@peakreachfd@gmail.com or call us at +***-**-****-****.',
  },
  // {
  //   question:
  //     "Where can I find information about allergies and dietary restrictions?",
  //   answer:
  //     "Each restaurant’s menu includes details about ingredients and allergens. If you have specific dietary concerns, please contact the restaurant directly through the platform to ensure your needs are met.",
  // },
  {
    question: "Can I schedule orders in advance?",
    answer:
      "Not yet, but we are working towards having that feature in the nearest future.",
  },
  {
    question: "How do I leave a review or rate my experience?",
    answer:
      "After your order is delivered, you will receive a prompt to rate your experience and leave a review for the restaurant and the meal. Your feedback helps us improve our service.",
  },
  {
    question: "Are there any delivery fees?",
    answer:
      "Delivery fees vary depending on the restaurant and your location. You can see the delivery fee during the checkout process before placing your order.",
  },
];
const agentFaq = [
  {
    question: "How do I complete my registration?",
    answer:
      "Once you register you will receive an email with instructions on how to perform the physical verification. Once verified, you will be required to also verify your registration email in the app to complete your onboarding.",
  },
  {
    question: "What equipment do I need?",
    answer:
      "You’ll need a smartphone with the courier app installed, a reliable mode of transportation (bike), an insulated delivery bag.",
  },
  {
    question: "How do I accept delivery requests?",
    answer:
      "After a successful registration on the Peakreach Agent app, you’ll start receiving delivery requests. To accept requests, tap the request notification to open the request. Tap on the accept button to accept the request.",
  },
  {
    question: "Can I choose my working hours?",
    answer:
      "Yes, Peakreach allows flexibility so therefore as an agent you can set your availability in the app and work whenever you want. There are no mandatory hours or shifts.",
  },
  {
    question: "What should I do if I can't find the customer’s address?",
    answer:
      "Use the in-app navigation or your preferred GPS service to locate the address. If you still have trouble, contact the customer via the app's messaging feature for further directions.",
  },
  {
    question: "What if an order is incorrect or missing items?",
    answer:
      "Notify the customer and support team immediately through the app. Follow their instructions, which may include returning to the restaurant or proceeding with the delivery.",
  },
  {
    question: "How am I paid for deliveries?",
    answer:
      "You are paid per delivery, which includes a base fare, distance traveled, and any tip from customers. Earnings made are deposited in your Reachwallet which is made accessible to you at the end of each day.",
  },
  {
    question: "Are there bonuses or incentives?",
    answer:
      "Yes, the platform offers various incentives such as sign up bonuses, referral bonuses, and completion bonuses for meeting certain delivery targets. Check the app regularly for current promotions.",
  },
  // {
  //   question: "What should I do if my app is not working correctly?",
  //   answer:
  //     "First, try restarting your phone and the app. Ensure you have a stable internet connection. If the issue persists, contact support through the app for further assistance.",
  // },
  {
    question: "How can I get help if I encounter a problem during a delivery?",
    answer:
      "Use the in-app support feature to get immediate help. You can also call the support hotline if the issue is urgent.",
  },
  {
    question: "What safety measures should I follow?",
    answer:
      "Always wear a helmet when riding your bike and obey traffic laws, and be aware of your surroundings.",
  },
  {
    question: "How do I handle customer complaints or disputes?",
    answer:
      "Remain professional and courteous at all times. Direct the customer to contact customer support for resolution. Report any incidents through the app immediately.",
  },
  {
    question: "How do I update my banking details?",
    answer:
      "Go to the reach wallet, click on the gear icon. There you will see your bank details. Click on update to change the details.",
  },
];

const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h2 className="faq-title">Frequently Asked Questions</h2>
      <h2 className="sub-faq-title">Customers</h2>
      <div className="faq-items-container">
        {customerFaq.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              {item.question}
            </div>
            {activeIndex === index && (
              <div className="faq-answer">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
      <h2 className="sub-faq-title">Restaurants</h2>
      <div className="faq-items-container">
        {restaurantFaq.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              {item.question}
            </div>
            {activeIndex === index && (
              <div className="faq-answer">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
      <h2 className="sub-faq-title">Agents</h2>
      <div className="faq-items-container">
        {agentFaq.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              {item.question}
            </div>
            {activeIndex === index && (
              <div className="faq-answer">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;

import LandingPageLayout from "../../components/landing-page/LandingPageLayout";
import { FooterSection } from "./components";
import "./styling/terms-and-condition.scss";

function TermsOfCollaboration() {
  return (
    <LandingPageLayout inverse={true}>
      <section className="terms-and-conditions-container">
        <h1>Contract Agreement</h1>

        <div className="content-container">
          <section className="contract-section">
            {/* <h1>Contract Agreement</h1> */}
            <div className="contract-content">
              <p>
                This Contract Agreement (the "Agreement") is made and entered
                into between Peakreach (hereinafter referred to as Peakreach)
                and the partner Restaurant (hereinafter referred to as the
                "Restaurant") collectively referred to as the "Parties."
              </p>

              <h2>Scope of Services</h2>
              <p>
                That Peakreach, an Abuja-based Nigerian company runs food
                delivery services for restaurants and food consumers
                (hereinafter referred to as the Customers) through her mobile
                application and online platform (hereinafter referred to as the
                Peakreach App).
              </p>
              <p>
                That the Restaurant wishes to offer all of Peakreach Customers
                its menu items, hence benefiting from the Peakreach App
                commercially.
              </p>
              <p>
                Peakreach agrees to provide food delivery services for the
                Restaurant's menu items through its App. The services shall
                include receiving and processing customer orders, coordinating
                delivery logistics, and ensuring timely delivery to customers.
              </p>

              <h2>Obligations of Peakreach</h2>
              <p>
                a. Peakreach will make the Restaurant's menu items available for
                online ordering on its platform and mobile application.
              </p>
              <p>
                b. The Platform will handle customer inquiries and provide
                customer support related to the Restaurant's food delivery
                orders.
              </p>
              <p>
                c. Peakreach will dispatch delivery drivers to pick up orders
                from the Restaurant and deliver them to customers within a
                reasonable timeframe.
              </p>
              <p>
                d. Peakreach will remit payments to the Restaurant for fulfilled
                orders according to the agreed-upon payment terms.
              </p>

              <h2>Obligations of the Restaurant</h2>
              <p>
                a. The Restaurant will provide accurate and up-to-date
                information regarding its menu items, pricing, and availability
                to Peakreach upon registration.
              </p>
              <p>
                b. The Restaurant will promptly accept, process, prepare, and
                package orders for pickup by the Platform's delivery drivers,
                ensuring that food quality and presentation are maintained.
              </p>
              <p>
                c. The Restaurant will ensure compliance with all applicable
                food safety, hygiene, and licensing regulations.
              </p>
              <p>
                d. In line with Peakreach’s brand representation and
                positioning, the restaurant shall adhere to using all marketing
                materials provided by Peakreach in the preparation of each
                order.
              </p>
              <p>
                e. The Restaurant will promptly address any customer concerns or
                complaints related to the quality, accuracy, or delivery of the
                food.
              </p>
              <p>f. Delivery time.</p>
              <p>g. Communication channel.</p>
              <p>
                h. The Restaurant shall accept complaints from customers as
                communicated by Peakreach and swiftly fix errors.
              </p>
              <p>
                i. All updates on the restaurant’s physical store shall be duly
                communicated to Peakreach to ensure the restaurant’s profile on
                the App is equally updated.
              </p>

              <h2>Payment Terms</h2>
              <p>
                a. The partner agrees to a commission fee of 18% on the
                gross/total food order value for each order fulfilled through
                Peakreach. This fee is exclusive of VAT, consumption tax, and
                processing fees. The 18% payment covers fees charged for using
                the platform as an online store. The total payment would hence
                be calculated thus; 18% + VAT(%) + consumption(%)., hereinafter
                referred to as PARTNERSHIP FEE.
              </p>
              <p>
                b. Peakreach consents to remit to the restaurant all of the
                sales generated on the App exclusive of the partnership fees as
                agreed in the clause above.
              </p>
              <p>
                c. Payment will be made by Peakreach to the Restaurant on a
                Daily basis, providing a detailed statement of orders fulfilled
                and commission fees deducted.
              </p>
              <p>
                d. The Restaurant acknowledges that taxes and payment processing
                fees may be deducted from the total amount payable.
              </p>
              <p>
                e. The parties consent the percentage for the calculation of the
                Partnership Fee has been unanimously agreed upon.
              </p>
              <p>
                f. The partnership fee entitles the restaurant to the following
                Peakreach services; periodic marketing on the Peakreach App and
                social media pages as well as visibility to the database of the
                App’s users, creation of the store profile in the APP, sales
                generation, payment gateway, 24-hour customer and restaurant
                support.
              </p>

              <h2>Confidentiality and Data Protection</h2>
              <p>
                a. Both Parties shall maintain the confidentiality of any
                confidential or proprietary information shared during the course
                of this Agreement.
              </p>
              <p>
                b. The platform will handle customer data collected during the
                ordering process in compliance with applicable data protection
                and privacy laws.
              </p>

              <h2>Terms and Termination</h2>
              <p>
                a. This Agreement shall commence on the effective date and
                remain in effect for an initial term of 12 months and will
                automatically renew for subsequent 12 months term unless either
                Party provides written notice of termination at least 3 months
                prior to the expiration of the current term.
              </p>
              <p>
                b. Either Party may terminate this Agreement with immediate
                effect in the event of a material breach of its obligations by
                the other Party.
              </p>
              <p>
                c. In the event of termination, the Parties shall settle any
                outstanding obligations and return any property or confidential
                information belonging to the other Party.
              </p>

              <h2>Governing Law and Dispute Resolution</h2>
              <p>
                This Agreement shall be governed by and construed in accordance
                with the laws of the Nigerian Constitution. Any disputes arising
                out of or in connection with this Agreement shall be resolved
                amicably through good faith negotiations. If the Parties are
                unable to reach a resolution, the dispute shall be submitted to
                binding arbitration in accordance with the rules of NICArb in
                Nigeria.
              </p>

              <h2>Entire Agreement</h2>
              <p>
                This Agreement constitutes the entire understanding and
                agreement between the Parties concerning the subject matter
                herein and supersedes all prior discussions.
              </p>
            </div>
          </section>
        </div>
      </section>
      <FooterSection />
    </LandingPageLayout>
  );
}

export default TermsOfCollaboration;

import {
    MDBBtn,
} from "mdb-react-ui-kit";
import { Dispatch, SetStateAction, useState } from "react";
import { useAppDispatch } from "../app/hooks";
import { setActivated } from "../features/slice/restaurant/authStatusSlice";

type ModalResourceProps = {
    title?: string,
    message?: any,
    icon?: any
}


export const SuccessModal = (props: ModalResourceProps) => {
    const { title, icon, message } = props;// when isActivated is false, user has not activated his/her account, the successModal is set to true --- inverse of isActivated
    const dispatch = useAppDispatch();

    const closeModal = () => {
        dispatch(setActivated({ isActivated: true }))
    }


    return(
        <div className="success-modal-container" tabIndex={-1}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="btn-container">
                        <MDBBtn onClick={closeModal} className='btn-close' color='none'>
                        </MDBBtn>
                    </div>
                    <div>
                        <div className='modal-icon'>
                            {icon}
                        </div>
                        <h5 className="modal-title">{title}</h5>
                        <div className="modal-body">{ message }</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from "react";
import { MDBContainer, MDBInput } from "mdb-react-ui-kit";
import { Add, AddAPhotoOutlined } from "@mui/icons-material";
import { NavLink, useNavigate } from "react-router-dom";
import { PageLayout } from "../../../components/restaurant-portal/PageLayout";
import { TimeSelectionModal } from "../../../components/timeModal";
// import AddressInput from "../../../components/addressInput";
import {
  useLazyGetRestaurantProfileQuery,
  useUpdateRestaurantProfileMutation,
  useUploadMealImageMutation,
} from "../../../features/services/restaurant/service";
import { toast } from "react-toastify";
import Loader from "../../../components/loader";
import axios from "axios";

type BusinessHoursProps = {
  day: string;
  open: string;
  close: string;
};

export const RestaurantProfileEdit = () => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [uploadedImage, setUploadedImage] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [businessHours, setBusinessHours] = useState<BusinessHoursProps[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  // const [opening_hours, setOpeningHours] = useState("");
  const [uploading, setUploading] = useState(false);

  const navigate = useNavigate();
  //////////////////
  //Mutations and queries

  const [uploadMealImage, { uploadStatus, isUploadingImg, errorUploadingImg }] =
    useUploadMealImageMutation({
      selectFromResult: ({ data, isLoading, error }) => ({
        uploadStatus: data,
        isUploadingImg: isLoading,
        errorUploadingImg: error,
      }),
    });
  const [getRestaurantProfile, { data: initialProfileDetails }] =
    useLazyGetRestaurantProfileQuery();
  const [
    updateRestaurantProfile,
    {
      data: profileUpdated,
      isLoading: isUpdating,
      isSuccess,
      error: error_updatingProfile,
    },
  ] = useUpdateRestaurantProfileMutation();
  ///////////////////////////////////////////////////////////////
  //FUNCTIONS

  const showFileInput = () => hiddenFileInput.current?.click();
  useEffect(() => {
    setUploadedImage(initialProfileDetails?.image_url);
    setName(initialProfileDetails?.name);
    setAddress(initialProfileDetails?.address);
    // setPhone(initialProfileDetails?.phone);
    // setBusinessHours(initialProfileDetails?.days_open);
  }, [initialProfileDetails]);
  const handleUpload = () => {
    setUploading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/image-upload`,
        { image: selectedImage },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res: any) => {
        setUploading(false);
        setUploadedImage(res?.data?.url);
        toast.success("Image uploaded successfully");
      })
      .catch((err) => {
        setUploading(false);
        toast.error("Error uploading image");
      });
  };

  //initial image upload function
  // const handleUpload = async () => {
  //   if (!selectedImage) return;
  //   const formData = new FormData();
  //   formData.append("image", selectedImage);
  //   try {
  //     await uploadMealImage(formData).unwrap();
  //   } catch (error) {
  //     toast.error("Error uploading Image! Try again");
  //   }
  // };
  const handleSubmit = (e: any) => {
    e.preventDefault();

    // if (businessHours.length > 0 && uploadedImage && name && phone) {
    // this checks if the initial phone number is the same as the new one same with restaurant name
    // if (
    //   initialProfileDetails?.phone === phone &&
    //   initialProfileDetails?.name === name
    // ) {
    //   setName("");
    //   setPhone("");
   
    updateRestaurantProfile({
      name,
      phone,
      image_url: uploadedImage,
      days_open: businessHours,
    });
    // }
    // } else {
    //   toast.error("Bussiness hours are required");
    // }
  };

  ///////////////////
  // useEffects
  useEffect(() => {
    getRestaurantProfile();
  }, []);

  useEffect(() => {
    setUploadedImage(uploadStatus?.url);
  }, [uploadStatus, errorUploadingImg]);

  useEffect(() => {
    if (selectedImage) {
      handleUpload();
    }
  }, [selectedImage]);
  useEffect(() => {
    if (isSuccess) {
      toast.success("Profile updated successfully");
      navigate("/restaurant-portal/profile");
    }
    if (errorUploadingImg) {
      toast.error("Error! ensure file size is less than 250kb");
    }
    if (error_updatingProfile) {
      toast.error("Error updating profile");
      toast.error("Only update fields that needs changing");
    }
  }, [uploadStatus, errorUploadingImg, isSuccess, error_updatingProfile]);

  //this was moved here to prevent so the check can be done after bussiness hour has been set to initial days_open before edit
  const isEmpty = () => {
    if (businessHours?.length > 0) {
      return true;
    }
  };

  return (
    <>
      <PageLayout>
        <MDBContainer
          fluid={true}
          breakpoint="md"
          className="profile-edit-container"
        >
          <div className="nav-link">
            <h1>Edit Profile</h1>
            <NavLink to="/restaurant-portal/profile" className="edit-button">
              BACK
            </NavLink>
          </div>
          <section>
            <form onSubmit={handleSubmit}>
              <div className="photo-upload">
                {!uploadedImage && !uploading ? (
                  <AddAPhotoOutlined fontSize="large" onClick={showFileInput} />
                ) : uploading ? (
                  <Loader
                    height="27px"
                    width="27px"
                    borderTopColor="#FFAE0A"
                    borderTopWidth="5px"
                    borderWidth="5px"
                  />
                ) : (
                  <img
                    src={uploadedImage}
                    alt=""
                    width="100%"
                    height="250px"
                    onClick={showFileInput}
                  />
                )}
              </div>
              <input
                type="file"
                accept="image/*"
                className="hidden-input"
                ref={hiddenFileInput}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  // setSelectedImage((e.target as HTMLInputElement)?.files?.[0])
                  e.target.files && setSelectedImage(e.target.files[0])
                }
              />
              <div className="inputs-container">
                <MDBInput
                  label="Restaurant name"
                  id="restaurantName"
                  type="text"
                  size="lg"
                  onChange={(e) => setName(e.target.value.toLowerCase())}
                  value={name}
                />
                <br />

                <div>{/* <AddressInput /> */}</div>
                <br />

                <MDBInput
                  label={`Contact phone: ${initialProfileDetails?.phone}`}
                  id="restaurantPhone"
                  type="tel"
                  size="lg"
                  onChange={(e) => setPhone(e.target.value.toLowerCase())}
                  value={phone}
                />
                <br />
                <MDBInput
                  label="Opening hours"
                  id="openingHours"
                  type="text"
                  size="lg"
                  readOnly={true}
                  value={
                    isEmpty() &&
                    businessHours?.map(
                      (item) => ` ${item.day}: ${item.open}-${item.close}`
                    )
                  }
                />
                <br />
                <div onClick={() => setShowModal(true)} className="show-modal">
                  <Add /> ADD NEW HOURS
                </div>

                <button className="w-100 save-button" type="submit">
                  {isUpdating ? (
                    <Loader
                      height="27px"
                      width="27px"
                      borderTopColor="#FFAE0A"
                      borderTopWidth="5px"
                      borderWidth="5px"
                    />
                  ) : (
                    "PROCEED"
                  )}
                </button>
              </div>
            </form>
          </section>
        </MDBContainer>
      </PageLayout>
      {/* Add opening hours modal  */}
      {showModal && (
        <TimeSelectionModal
          setModalDisplay={setShowModal}
          setBusinessTime={setBusinessHours}
        />
      )}
    </>
  );
};

import LandingPageLayout from "../../components/landing-page/LandingPageLayout";
import { FooterSection } from "./components";
import "./styling/terms-and-condition.scss";

function CustomerUsepolicy() {
  return (
    <LandingPageLayout inverse={true}>
      <section className="terms-and-conditions-container">
        <h1>Customers Use Policy</h1>
        <div className="aup-content">
          <h2>1. General Guidelines</h2>
          <p>
            1.1. Eligibility: To use Peakreach, you must be of legal age of 18
            years and above or have the consent of a legal guardian. We do not
            knowingly collect data from individuals under the legal age.
          </p>
          <p>
            1.2. Account Security: Protect your account credentials and personal
            information. Peakreach will never ask for your password or sensitive
            details via email or any other channel. If you suspect unauthorized
            access to your account, please report it immediately.
          </p>
          <p>
            1.3. Respect: Interact with other users, vendors, and delivery
            riders with respect and courtesy. Discrimination, harassment, hate
            speech, or any form of abusive behavior will not be tolerated.
          </p>
          <p>
            1.4. Compliance: Comply with all applicable laws and regulations in
            your jurisdiction, including food safety standards, taxation, and
            labour laws.
          </p>

          <h2>2. Our Services</h2>
          <p>
            2.1. Peakreach has a diverse array of restaurants, giving you the
            flexibility to choose and order their preferred dishes for
            convenient pickup and delivery.
          </p>
          <p>
            2.2. Users can seamlessly make orders of their favourite meals and
            have them swiftly delivered by our stream of dedicated Riders from
            nearby restaurants using Peakreach.
          </p>
          <p>
            2.3. Operating the platform involves deploying tools and technical
            means to facilitate a seamless user experience. We don't physically
            possess any of the food listed for sale, and Peakreach remains
            entirely independent of the restaurants featured on the platform.
          </p>
          <p>
            2.4. Peakreach has no affiliations or partnerships with the featured
            restaurants. Our sole responsibility lies in efficiently purchasing
            or ordering food on behalf of users, precisely following their
            instructions to ensure a smooth delivery process, as we take charge
            of executing their orders.
          </p>
          <p>
            2.5. While we guarantee that our delivery services are excellent, we
            cannot do the same for the meals ordered through Peakreach. We
            disclaim any guarantee or warranty that pertains to this.
          </p>
          <p>
            2.6. We lack control over and cannot guarantee the existence,
            quality, or safety of the food advertised. Furthermore, we do not
            vouch for the accuracy of restaurant offers or their ability to
            produce a meal as described.
          </p>

          <h2>3. Customers Responsibilities</h2>
          <p>
            3.1. Ordering: Use Peakreach for ordering food only from our
            authorized vendors. Respect their menu and pricing.
          </p>
          <p>
            3.2. Payment: Ensure the accuracy of your payment information.
            Fraudulent activities, including chargebacks, will result in
            suspension.
          </p>
          <p>
            3.3. Feedback: Provide constructive and fair feedback on vendors and
            their products. False or misleading reviews are not permitted.
          </p>
          <p>
            3.4. It is your duty to verify the delivery details and ensure the
            precision of the provided address and phone number when using
            Peakreach.
          </p>
          <p>
            3.5. Upon placing an order, you commit to accepting the meals
            ordered through Peakreach. In the event of any issues, such as
            inaccuracies or mix-ups, you agree to reach out to Peakreach to
            request a return.
          </p>
          <p>
            3.6. Endeavour to keep the order in its delivered condition, refrain
            from alterations that would render returning it to its original
            state unfeasible.
          </p>

          <h2>4. Payment</h2>
          <p>
            4.1. Peakreach processes all in-app payments through a third-party
            payment gateway, ensuring secure online transactions.
          </p>
          <p>
            4.2. The responsibility for all payment transactions on the
            Peakreach app rests solely with the payment processor.
          </p>
          <p>
            4.3. To successfully place an order, you have the option of making
            payments via debit cards or other methods provided by our payment
            service partners.
          </p>
          <p>
            4.4. Meal sale prices, displayed in Naira, are inclusive of any
            applicable taxes as specified in the offers.
          </p>
          <p>
            4.5. Proceed to the checkout page to access the delivery fee for all
            orders.
          </p>
          <p>
            4.6. The summed price, as indicated on the checkout page, should
            either be paid upon confirming the order or paid in cash on
            delivery.
          </p>

          <h2>5. Order Cancellation & Refunds</h2>
          <h3>5.1. Order Cancellation</h3>
          <p>
            <strong>A. Cancellation by Customer</strong>
          </p>
          <p>
            <strong>Before Confirmation:</strong> customers can cancel their
            orders at any time before the restaurant confirms them, with no
            charges applied.
          </p>
          <p>
            <strong>After Confirmation:</strong> Once the restaurant has
            confirmed the order, cancellation may not be possible, but if the
            necessity arises contact customer support.
          </p>
          <p>
            <strong>B. Cancellation by Peakreach</strong>
          </p>
          <p>
            Peakreach retains the right to cancel orders for various reasons
            such as item unavailability, errors in order details, or delivery
            issues. In such events, customers will be promptly informed and a
            full refund will be provided.
          </p>
          <h3>5.2. Refunds</h3>
          <p>
            <strong>A. Refund Eligibility</strong>
          </p>
          <p>
            <strong>Cancelled Orders:</strong> If an order is cancelled before
            confirmation, a full refund will be issued. If an order is cancelled
            after confirmation, the refund amount will be subjected only to the
            delivery fee for the cancelled order.
          </p>
          <p>
            <strong>Order Issues:</strong> If there are problems with the order,
            such as missing items, incorrect items, or other delivery issues,
            customers must report them within 24 hours of receiving the
            delivery. Peakreach will investigate and determine eligibility for a
            full or partial refund or replacement.
          </p>
          <p>
            <strong>B. Refund Process</strong>
          </p>
          <p>
            In the case of a Refund, Peakreach will contact the customer to make
            arrangements for the refund which will be processed within 1-5 days
            business days from the date of confirmation of the refund.
          </p>
          <p>
            <strong>C. Non-Refundable Situations</strong>
          </p>
          <p>Refunds will not be issued in the following circumstances:</p>
          <p>
            i. If the customer fails to report order issues within 24 hours of
            delivery.
          </p>
          <p>
            ii. If the order was delivered to the correct address and the
            customer was either not available or did not respond.
          </p>
          <p>
            iii. For subjective complaints about quality such as taste
            preferences, unless a clear quality issue is confirmed by Peakreach.
          </p>
          <p>
            <strong>D. How to Request a Refund</strong>
          </p>
          <p>
            Customers can request a refund by contacting our customer service
            team through the Peakreach app, email or contact phone number and
            provide the order number, details of the issue, and any relevant
            evidence (e.g., photos of incorrect items) when requesting a refund.
          </p>

          <h2>6. Prohibited Activities</h2>
          <p>
            6.1. Fraud: Engaging in fraudulent activities, including identity
            theft, payment fraud, or manipulation of the platform, is strictly
            prohibited.
          </p>
          <p>
            6.2. Illegal Activities: Using Peakreach for illegal activities,
            such as the sale of illicit substances or services, is not allowed.
          </p>
          <p>
            6.3. Spam and Misuse: Do not engage in spamming, phishing, or any
            form of abusive behavior. Do not misuse Peakreach for any purpose
            other than its intended use.
          </p>

          <h2>7. Consequences of Violation</h2>
          <p>
            Peakreach reserves the right to take appropriate action, which may
            include suspension, termination of accounts, legal action, or
            reporting to law enforcement, for any breach of this Acceptable Use
            Policy.
          </p>

          <h2>8. Reporting Violations</h2>
          <p>
            If you believe someone has violated this policy or you have concerns
            about the safety and security of the Peakreach platform, please
            contact our customer support team immediately. We take all reports
            seriously and will investigate them promptly.
          </p>

          <h2>9. Customer Care</h2>
          <p>
            At Peakreach, we prioritize providing timely and empathetic support
            to address any concerns or issues. Our dedicated customer care team
            is committed to ensuring a positive experience for every customer.
            We aim to respond promptly to inquiries, resolve issues efficiently,
            and continuously improve our service based on valuable feedback from
            our customers. We provide seamless and quality customer assistance
            with real-time customer care personnels standby to keep you informed
            about the status of your orders, provide guaranteed swift solutions
            and resolutions to issues if any arises.
          </p>

          <h2>10. Data Privacy and Security</h2>
          <p>
            10.1. Data Protection: Peakreach is committed to protecting your
            personal data. We collect and process personal information in
            accordance with our Privacy Policy. Ensure that you understand how
            your data is used and stored.
          </p>
          <p>
            10.2. Account Security: Take appropriate measures to secure your
            account and personal information. Use strong and unique passwords,
            enable two-factor authentication, and avoid sharing account details
            with others.
          </p>

          <h2>11. Intellectual Property</h2>
          <p>
            11.1. Copyright and Trademarks: Respect the intellectual property
            rights of others. Do not use, upload, or share content that
            infringes on copyrights or trademarks without proper authorization.
          </p>
          <p>
            11.2. Should you suspect that our Platform's content has, in any
            way, violated your copyright, we encourage you to reach out to us.
            We assure you that prompt action will be taken to halt or rectify
            any such breach upon receipt of your notification.
          </p>

          <h2>12. Prohibited Content</h2>
          <p>
            12.1. Harmful Content: Do not share, upload, or promote content that
            is harmful, violent, explicit, or obscene. This includes but is not
            limited to hate speech, sexually explicit material, and graphic
            violence.
          </p>
          <p>
            12.2. Misinformation: Do not spread false or misleading information,
            including rumors, fake news, or conspiracy theories, especially
            related to public health and safety.
          </p>

          <h2>13. Complaints and Dispute Resolution</h2>
          <p>
            13.1. Complaint Handling: Peakreach is committed to addressing
            complaints and disputes fairly. If you have a complaint, please
            contact our customer support team, and we will work to resolve the
            issue promptly.
          </p>

          <h2>14. Changes to the AUP</h2>
          <p>
            Policy Updates: Peakreach reserves the right to update this
            Acceptable Use Policy. Any changes will be communicated to users,
            and it is your responsibility to review and comply with the updated
            policy.
          </p>

          <h2>15. Terms and Termination</h2>
          <p>
            From the moment you begin utilizing our services, this usage
            agreement takes effect and remains in force until you close your
            account or we decide to terminate it.
          </p>

          <h2>16. Indemnification</h2>
          <p>
            16.1.a. You agree to protect, defend, and exonerate Peakreach from
            any liabilities, damages, losses, or claims that may result from how
            you use our services.
          </p>
          <p>16.1.b. Violation of these terms of use or any applicable law.</p>
          <p>
            16.2. We retain the right, at your expense, to assume exclusive
            defense and control of any matter requiring your indemnification.
            Your cooperation with our defense of such claims is expected.
          </p>
          <p>
            16.3. Peakreach holds no liability, and you agree not to hold
            Peakreach responsible for:
          </p>
          <p>
            a. Delays or disruptions in our Services, software, and Platform.
          </p>
          <p>b. Your use or inability to access our Services or Platform.</p>
          <p>
            c. Damage caused by viruses or technologically harmful material
            resulting from Platform use or linked websites.
          </p>
          <p>
            d. Any access or use of our services inconsistent with these terms
            of use.
          </p>
          <p>e. Errors, inaccuracies, losses, or omissions in provided data.</p>
          <p>
            f. Suspensions or other actions taken with your Peakreach account.
          </p>
          <p>
            g. We are not liable to any user for loss or damage, whether in
            contract, tort (including negligence), breach of statutory duty, or
            otherwise, even if foreseeable, arising from the use or inability to
            use our Platform.
          </p>

          <h2>17. Governing Law and Jurisdiction</h2>
          <p>
            Jurisdiction: This policy is governed by and construed in accordance
            with the laws of Nigeria. Any disputes arising from or related to
            this policy will be subject to the exclusive jurisdiction of the
            courts in Nigeria.
          </p>
        </div>
      </section>
      <FooterSection />
    </LandingPageLayout>
  );
}

export default CustomerUsepolicy;

// import React from "react";
import { LandingPage } from "../../pages";
import {
  RestaurantSignIn,
  RestaurantSignUp,
  ForgotPassword,
} from "../../pages/restaurant-portal/auth";
import {
  OrderManager,
  RestaurantProfile,
  RestaurantProfileEdit,
  RestaurantReview,
  RestaurantMenuManager,
  RestaurantAddMenu,
  RestaurantUpdateItem,
  AccountActivation,
  RestaurantNotification,
} from "../../pages/restaurant-portal/dashboard";

import { ErrorPage } from "../../pages/utility/ErrorPage";
import ProtectedRoute from "../../components/ProtectedRoute";
import GetStartedPage from "../../pages/landing-page/GetStartedPage";
import PrivacyPolicy from "../../pages/landing-page/PrivacyPolicy";
import TermsAndCondition from "../../pages/landing-page/TermsAndConditions";
import TermsOfCollaboration from "../../pages/landing-page/TermsOfCollaboration";
import CustomerUsepolicy from "../../pages/landing-page/CustomerUsepolicy";
import AgentsUsepolicy from "../../pages/landing-page/AgentsUsepolicy";
import MealsCategories from "../../pages/landing-page/MealCategories";

type UserRouteType = {
  path: string;
  element: JSX.Element;
  exact?: boolean;
};

const routes: Array<UserRouteType> = [
  { path: "/", element: <LandingPage /> },
  { path: "/get-started", element: <GetStartedPage /> },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/terms-and-conditions", element: <TermsAndCondition /> },
  { path: "/terms-of-collaboration", element: <TermsOfCollaboration /> },
  { path: "/customer-use-policy", element: <CustomerUsepolicy /> },
  { path: "/agent-use-policy", element: <AgentsUsepolicy /> },
  { path: "/meal-categories", element: <MealsCategories /> },

  // Restaurant portal routes
  { path: "/restaurant-portal/signin", element: <RestaurantSignIn /> },
  { path: "/restaurant-portal/signup", element: <RestaurantSignUp /> },
  { path: "/restaurant-portal/forgotpassword", element: <ForgotPassword /> },
  {
    path: "/restaurant-portal/dashboard",
    // element: <OrderManager />
    element: (
      <ProtectedRoute
        redirectPath="/restaurant-portal/signin"
        children={<OrderManager />}
      />
    ),
  },
  {
    path: "/restaurant-portal/profile",
    // element: <RestaurantProfile />
    element: (
      <ProtectedRoute
        redirectPath="/restaurant-portal/signin"
        children={<RestaurantProfile />}
      />
    ),
  },
  {
    path: "/restaurant-portal/profile/edit",
    // element: <RestaurantProfileEdit />
    element: (
      <ProtectedRoute
        redirectPath="/restaurant-portal/signin"
        children={<RestaurantProfileEdit />}
      />
    ),
  },
  {
    path: "/restaurant-portal/account-activation",
    // element: <AccountActivation />
    element: <AccountActivation />,

    // element: <ProtectedRoute
    //             redirectPath="/restaurant-portal/signin"
    //             children={<AccountActivation />}
    //         />
  },
  {
    path: "/restaurant-portal/reviews",
    // element: <RestaurantReview />
    element: (
      <ProtectedRoute
        redirectPath="/restaurant-portal/signin"
        children={<RestaurantReview />}
      />
    ),
  },
  {
    path: "/restaurant-portal/menu-manager",
    // element: <RestaurantMenuManager />
    element: (
      <ProtectedRoute
        redirectPath="/restaurant-portal/signin"
        children={<RestaurantMenuManager />}
      />
    ),
  },
  {
    path: "/restaurant-portal/menu-manager/add-item",
    // element: <RestaurantAddMenu />
    element: (
      <ProtectedRoute
        redirectPath="/restaurant-portal/signin"
        children={<RestaurantAddMenu />}
      />
    ),
  },
  {
    path: "/restaurant-portal/menu-manager/update-item/:meal_id",
    // element: <RestaurantUpdateItem />
    element: (
      <ProtectedRoute
        redirectPath="/restaurant-portal/signin"
        children={<RestaurantUpdateItem />}
      />
    ),
  },
  {
    path: "/restaurant-portal/notifications",
    // element: <RestaurantNotification />
    element: (
      <ProtectedRoute
        redirectPath="/restaurant-portal/signin"
        children={<RestaurantNotification />}
      />
    ),
  },

  { path: "*", element: <ErrorPage /> },
];

export { routes };

import { DeleteOutline } from "@mui/icons-material";
import { currencyFormatter, truncateText } from "../../utils/methods/methods";
import edit from "../../assets/images/restaurant/dashboard/edit.svg";
import "./menuCard.scss";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { useState } from "react";

type CardProps = {
  id: string;
  image: string;
  name: string;
  desc: string;
  price: number;
  is_in_stock: boolean;
  updatingMeal: boolean;
  handleStockStatus?: () => void;
  onClick?: () => void;
};

export const MenuCard = (props: CardProps) => {
  const {
    id,
    image,
    name,
    price,
    desc,
    is_in_stock,
    updatingMeal,
    onClick,
    handleStockStatus,
  } = props;
  const [showActions, setShowActions] = useState(false);

  return (
    <div className="menu-card-container">
      <div
        className="img-container"
        style={{ opacity: `${is_in_stock ? "1" : "0.5"}` }}
      >
        <img className="card-img" src={image} alt="" />
        {!is_in_stock && <span>Out of stock</span>}
      </div>
      <div className="detail-container">
        <div className="menu-detail">
          <div>
            <h4>{truncateText(name, 10)}</h4>
            <h4 className="price">&#8358;{currencyFormatter(price)} </h4>
          </div>
          <p className="desc">{truncateText(desc, 30)} </p>
        </div>
        <div className="edit-delete-container">
          <Link to={`/restaurant-portal/menu-manager/update-item/${id}`}>
            <img src={edit} alt="" title="Edit meal" />
          </Link>
          <div className="action-icon" title="Actions">
            {/* <DeleteOutline onClick={onClick} color="error" fontSize="medium" /> */}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() =>
                !showActions ? setShowActions(true) : setShowActions(false)
              }
            >
              <circle cx="12" cy="5" r="2" fill="currentColor" />
              <circle cx="12" cy="12" r="2" fill="currentColor" />
              <circle cx="12" cy="19" r="2" fill="currentColor" />
            </svg>
            <ul
              className="actions-dropdown"
              onMouseLeave={() => setShowActions(false)}
              style={{ visibility: `${showActions ? "visible" : "hidden"}` }}
            >
              {updatingMeal ? (
                <li>Updating...</li>
              ) : (
                <li onClick={handleStockStatus}>
                  {" "}
                  {is_in_stock ? "Out of Stock" : "Restocked"}{" "}
                </li>
              )}

              <li className="delete" onClick={onClick}>
                Delete Meal
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

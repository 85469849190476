import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

const initialState = {
    notifications: [],
    unreadNotification: 0,
};


const restaurantNotificationSlice = createSlice({
    name: 'restaurantNotifications',
    initialState,
    reducers: {
        addNotification: ( state: any, action: PayloadAction<{notifications: any}> ) => {
            state.notifications.push(action?.payload);
            state.unreadNotification += 1;
        },
        clearNotifications: ( state ) => {
            state.notifications = [];
            state.unreadNotification = 0;
        },
        markAsRead: ( state ) => {
            state.unreadNotification = 0
        }
    }

})


export const selectRestaurantNotifications = (state: RootState) => state.restaurantNotifications;
export const { addNotification, clearNotifications, markAsRead } = restaurantNotificationSlice.actions;
export default restaurantNotificationSlice.reducer;


import LogoInverse from "../../../assets/images/landing-page/logo-inverse.svg";
import Insta from "../../../assets/images/landing-page/instagram.png";
import Twitter from "../../../assets/images/landing-page/twitter.png";
import Facebook from "../../../assets/images/landing-page/facebook.png";
import "../../../assets/css/components.scss";
import { Link } from "react-scroll";

function FooterSection() {
  return (
    <footer>
      {/* <Grid container> */}
      <div className="grid">
        <div className="grid-1">
          <Link
            to="hero-section"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <img src={LogoInverse} alt="logo" />
          </Link>
          <div className="location">
            <h4>VISIT OUR HEADQUARTERS</h4>
            <p>Gwarinpa, Abuja</p>
          </div>
          <div className="call-us">
            <h4>CALL US</h4>
            <p>08128224769, 07042744334</p>
          </div>
          <div className="socials">
            <h4>CONNECT WITH US</h4>
            <ul>
              <li>
                <a href="www.twitter.com/_okafohenrie">
                  <img src={Insta} alt="instagram" height="24" width="23.5" />
                </a>
              </li>
              <li>
                <a href="www.twitter.com/_okafohenrie">
                  <img src={Twitter} alt="twitter" height="24" width="23.5" />
                </a>
              </li>
              <li>
                <a href="www.twitter.com/_okafohenrie">
                  <img src={Facebook} alt="facebook" height="24" width="23.5" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="grid-2">
          <div className="subscription-grid">
            <div>
              <h4>SUBSCRIBE TO OUR NEWSLETTER</h4>
              <div className="email-sub-container">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="email-textbox"
                />
                <button type="submit" className="subscribe">
                  SUBSCRIBE
                </button>
              </div>
            </div>
            <div>
              <div className="external-links">
                <ul>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                  <li>
                    <a href="/terms-and-conditions">Terms and Conditons</a>
                  </li>
                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                  <li>
                    <a href="/">FAQs</a>
                  </li>
                </ul>
              </div>
              <div className="copyright">
                <p>© Peakreach Copyright 2022</p>
              </div>
            </div>
          </div>
        </div>
        {/* </Grid> */}
      </div>
    </footer>
  );
}

export default FooterSection;

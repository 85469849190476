import LandingPageLayout from "../../components/landing-page/LandingPageLayout";
import { FooterSection } from "./components";
import appStore from "../../assets/images/landing-page/app-store.svg";
import playStore from "../../assets/images/landing-page/play-store.svg";
import web from "../../assets/images/landing-page/web.svg";
import "./styling/get-started.scss";
import { NavLink } from "react-router-dom";

function GetStartedPage() {
    return(
       <LandingPageLayout inverse={true}>
            <section className="get-started-container">
                <h1>Download Our Apps and Access Portals</h1>

                <div className="content-container">
                    <h2>Peakreach Mobile App</h2>
                    <p>The Peakreach User Mobile App is your gateway to a world of culinary delights. Order from a wide range of restaurants and enjoy speedy, contactless food delivery right to your doorstep. Whether it's a quick lunch or a special dinner, we've got you covered.</p>
                    <div className="image-container">
                        <img src={appStore} alt="app-store" />
                        <img src={playStore} alt="play-store" />
                    </div>
                </div>

                <div className="content-container">
                    <h2>Peakreach Agent App</h2>
                    <p>For our dedicated dispatch riders and delivery agents, the Peakreach Agent Mobile App is your command center. Accept delivery assignments, manage routes, and ensure efficient, on-time deliveries for our valued customers.</p>
                    <div className="image-container">
                        <img src={appStore} alt="app-store" />
                        <img src={playStore} alt="play-store" />
                    </div>
                </div>
              
                <div className="content-container">
                    <h2>Peakreach Logistics App</h2>
                    <p>The Peakreach Logistics App is designed for businesses and individuals who need to send packages with ease and speed. Organize your shipments, track deliveries, and enjoy reliable logistics services at your fingertips.</p>
                    <div className="image-container">
                        <img src={appStore} alt="app-store" />
                        <img src={playStore} alt="play-store" />
                        <img src={web} alt="web" />
                    </div>
                </div>

                <div className="content-container">
                    <h2>Restaurant Portal</h2>
                    <p>The Peakreach Restaurant Portal is your restaurant's command center. Manage orders, update menus, and streamline your operations. Deliver exceptional service to your customers with our user-friendly portal.</p>
                    <NavLink to="/restaurant-portal/signin" className="image-container">
                        <img src={web} alt="web" />
                    </NavLink>
                </div>
            </section>
            <FooterSection />
       </LandingPageLayout>
    )
}

export default GetStartedPage;
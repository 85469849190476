// Services Images
import OrderChoices from "../../assets/images/landing-page/order-choices.svg";
import Food from "../../assets/images/landing-page/food.svg";
import Delivery from "../../assets/images/landing-page/delivery.svg";
import PriceTag from "../../assets/images/landing-page/price-tag.svg";
// Features Images
import OrderFoodImage from "../../assets/images/landing-page/feature-order-food.svg";
import DeliveryImage from "../../assets/images/landing-page/feature-delivery.svg";
import BecomeVendorImage from "../../assets/images/landing-page/feature-become-vendor.svg";

// SERVICES SECTION
export const services = [
  {
    id: 132,
    imageSrc: OrderChoices,
    imageAlt: "order choices",
    headerText: "Easy To Order",
    subText: "The ordering process is easy and user-friendly",
  },
  {
    id: 1334,
    imageSrc: Delivery,
    imageAlt: "delivery",
    headerText: "Fast Delivery",
    subText: "Have your meal delivered to you under 30 minutes",
  },
  {
    id: 1794,
    imageSrc: Food,
    imageAlt: "food",
    headerText: "Best Quality",
    subText:
      "Get only quality meals from the best restaurants within your location",
  },
  {
    id: 1744,
    imageSrc: PriceTag,
    imageAlt: "price tag",
    headerText: "Fair Price",
    subText: "Pay a fair price for your meals and its delivery",
  },
];

export const features = [
  {
    id: 2245,
    imageSrc: OrderFoodImage,
    imageAlt: "order food using",
    imagePosition: "left",
    headerText: "Order food",
    subText:
      "Peakreach makes it simple for you to go over the menu of the best restaurants and place an order for home delivery, all on your mobile phone.",
    btnCount: 2,
    btnProps: null,
    url1: "../",
    url2: "../",
  },
  {
    id: 2896,
    imageSrc: DeliveryImage,
    imageAlt: "deliver and earn using peakreach",
    imagePosition: "right",
    headerText: "Ride for us",
    subText:
      "An opportunity to earn on your own terms! Register as a Peakreach agent and earn for each order you fulfill",
    btnCount: 2,
    btnProps: null,
    url1: "../",
    url2: "../",
  },
  {
    id: 2352,
    imageSrc: BecomeVendorImage,
    imageAlt: "become a vendor on peakreach",
    imagePosition: "left",
    headerText: "Become a vendor",
    subText:
      "Peakreach provides a wide platform for your restaurant. Register as a vendor and gain additional customers to boost your sales.",
    btnCount: 1,
    btnProps: {
      btnText: "SIGN UP",
      btnBg: "#FFAE0A",
      btnTextColor: "#0A0A0A",
      btnMargin: "0px",
    },
    url1: "/restaurant-portal/dashboard",
    url2: null,
  },
];

export const reviews = [
  {
    id: 3688,
    text: "“Everything about Peakreach is so cool and the service really helps professionals when they can’t be everywhere and do everything on time. “",
    author: "-William",
  },
  {
    id: 3885,
    text: "“Good food, Good options. Delivery partners are professional and the estimated time is usually proper. 100% recommend”",
    author: "-Joy",
  },
  {
    id: 3233,
    text: "“Great service, Good food, Good options. Delivery partners are professional and the estimated time is usually proper. 100% recommend”",
    author: "-Ayotunde",
  },
];

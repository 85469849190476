/* eslint-disable react-hooks/exhaustive-deps */
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";

import { AccountActivationProps } from "../restaurant.config";
import { Stages } from "../../../components";
import Loader from "../../../components/loader";
// import axios from "axios";
import { useGetActivationCodeMutation } from "../../../features/services/restaurant/service";
import { toast } from "react-toastify";

const ContractSigning = (props: AccountActivationProps) => {
  const { steps, index, setIndex, setFormData, formData } = props;
  const [email, setEmail] = useState<any>("");
  const [checked, setChecked] = useState(false);
  // const [loading, setLoading] = useState(false);

  const [
    getActivationCode,
    {
      data,
      isLoading,
      isSuccess: activation_code_success,
      error: activation_code_failed,
    },
  ] = useGetActivationCodeMutation();

  const handleSubmit = (e: any) => {
    setEmail(sessionStorage.getItem("contract_email"));
    e.preventDefault();
    if (checked) {
      const data = { email };
      getActivationCode(data);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (activation_code_success) {
      setIndex((prevState) => prevState + 1);
      localStorage.setItem("PEAKREACH_TOKEN", data?.token);
      toast.success("Activation code sent!");
      setFormData({ ...formData, mail_contract_to: email.toLowerCase() });
    }

    if (activation_code_failed) {
      toast.error("Failed to send activation code!");
    }
  }, [activation_code_failed, activation_code_success]);
  const handleChecked = () => setChecked((prevState: boolean) => !prevState);
  // useEffect(() => {
  //     setLoading(true);
  //     if(isSuccess){
  //         setLoading(false);
  //         setIndex((prevState) => prevState + 1);
  //         toast.success('An activation code has been sent to your email');
  //     }

  //     if(isError){
  //          setLoading(false);
  //          toast.error();
  //     }
  // }, [isSucess, isError]);

  return (
    <div>
      <MDBContainer className="account-activation">
        <h1>Account Activation</h1>
        <Stages steps={steps} step={index} />
        <form onSubmit={handleSubmit} className="contract-container">
          <div className="mt-4">
            <label htmlFor="code-email">
              Please read through our{" "}
              <a href="/terms-of-collaboration" target="_blank">
                terms of contract
              </a>
              . <br /> By checking the box below, you agree to the terms of this
              contract and wish to continue.
              {/* <span className="required-text">*</span> */}
            </label>
            <br />
            {/* <MDBInput
              type="email"
              id="code-email"
              size="lg"
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              required
            /> */}
            <div className="t_c_block mt-4">
              <input
                type="checkbox"
                color="red"
                id="t_&_c"
                checked={checked}
                onChange={handleChecked}
              />
              &nbsp;
              <label htmlFor="t_&_c">
                I agree to the terms of this contract and wish to continue.
              </label>
            </div>
            {!checked && (
              <small className="error-text">
                Kindly agree to our terms of contract to continue.
              </small>
            )}
          </div>

          <div className="form-btns mt-5">
            <MDBBtn className="back-btn" onClick={() => setIndex(index - 1)}>
              &larr; Back
            </MDBBtn>
            <MDBBtn className="proceed-btn" type="submit">
              {isLoading ? (
                <Loader
                  height="27px"
                  width="27px"
                  borderTopColor="#FFAE0A"
                  borderTopWidth="5px"
                  borderWidth="5px"
                />
              ) : (
                <>Proceed &rarr;</>
              )}
            </MDBBtn>
          </div>
        </form>
      </MDBContainer>
    </div>
  );
};

export default ContractSigning;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {  Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    MDBBtn,
    MDBInputGroup
} from 'mdb-react-ui-kit';
import {
    Visibility,
    VisibilityOff
} from '@mui/icons-material';

import { AuthLayout } from "../../../components";
import { FormDataProps } from '../restaurant.config';
import { Link } from 'react-router-dom';
import useWindowWidth from '../../../utils/methods/ui-hooks/getWindowWidth';
import { useRestaurantLoginMutation } from '../../../features/services/restaurant/service'; 
import { toast } from 'react-toastify';
import Loader from '../../../components/loader';


const data = {
    headingText: 'Hello there!',
    subText: "Create an account with us and we’ll help you reach a wider audience and boost your sales. If you already have an account with us, sign in here.",
    btnText: 'SIGN IN',
    btnPath: '/restaurant-portal/signin'
}

type Props = {
    index: number,
    setIndex: Dispatch<SetStateAction<number>>,
    setFormData: Dispatch<SetStateAction<FormDataProps>>,
    formData: FormDataProps
}


export  const SignUpLoginCredentials = ( props: Props ) => {
    const { index, setIndex, setFormData, formData } = props;
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm_password, setConfirm_Password] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [errors, setErrors] = useState({
        password: '',
        confirmPassword: '',
        email: ''
    });
    const screenWidth = useWindowWidth();
// renamed the loginQuery Service to emailVerification and used it to verify user existence on sign up 
    const [ emailVerification, {
        isSuccess,
        isError,
        error: verificationError
    }] = useRestaurantLoginMutation();

// check email structure validity 
    const validEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const handleEmail = (e: any) => {
        if(e.target.value.match(validEmailRegex)){
            setErrors({...errors, email: ''});
            setEmail(e.target.value)
        }else{
            setErrors({...errors, email: "Invalid email"});
        }
    }
// handle password check 
    const handlePassword = (e: any) => {
        if(e.target.value.length > 7){
            setPassword(e.target.value);
            setErrors({...errors, password: ''});
        }else{
            setErrors({...errors, password: 'Password must be atleast 8 characters long'});
        }
    }
// check if password matches confirm password 
    const handleConfirmPassword = (e: any) => {
        if(e.target.value === password){
            setConfirm_Password(e.target.value);
            setErrors({...errors, confirmPassword: ''})
        }else{
            setErrors({...errors, confirmPassword: 'Passwords do not match' })
        }
    }

    const handleProceed = async(e: any) => {
        e.preventDefault();
        if(errors.password === "" && errors.confirmPassword === "" && errors.email === ""){
            // check if email already exists in database using the login endpoint
            setLoading(true)
            await emailVerification({ email, password })
        }
    }

    useEffect(() => {
        setLoading(false);
        if(isSuccess){
            // setLoading(false);
            toast.error('Email exists, sign into your account');
        }
        if(isError){
            // setLoading(false);
            if(verificationError && "status" in verificationError){
                const { data }: any = verificationError;
                if(data?.error === "incorrect password"){
                    toast.error('Email exists, sign into your account');
                }else {
                    const user_email = email.toLowerCase();                    

                    setFormData({
                        ...formData, 
                        email: user_email, 
                        password, 
                    });
                    setIndex(index + 1);
                }

                // if(data.error === "restaurant not found. please check your email and password. "){                    
                //     const user_email = email.toLowerCase();                    

                //     setFormData({
                //         ...formData, 
                //         email: user_email, 
                //         password, 
                //     });
                //     setIndex(index + 1);
                // }else if(data.error === "incorrect password"){
                //     toast.error('Email exists, sign into your account');
                // }else{
                //     toast.error(data.error)
                // }
            }
        }

    }, [isSuccess, isError])

    return(
        <AuthLayout
            headingText= {data.headingText}
            subText= {data.subText}
            btnText= {data.btnText}
            btnPath= {data.btnPath}
        >
            <div className="login-credentials-container">
                <h1>Create Account</h1>
                <p className='desc-text'>Step 1 of 3 : Login credentials</p>
                <form  className='mt-5' onSubmit={handleProceed}>                    
                    <MDBInputGroup size='lg'>
                        <input
                            placeholder='Email'
                            type='email'
                            className='form-control'
                            onChange={handleEmail}
                            required 
                        />
                    </MDBInputGroup>
                    { errors?.email && <small className='error-text'>{errors?.email}</small> }
                    <MDBInputGroup className='password-group mt-4' size='lg'>                        
                        <input
                            placeholder='Password'
                            type={showPassword ? 'text' : 'password'}
                            className='form-control'
                            onChange={handlePassword}
                            required
                        />
                        <div onClick={() => setShowPassword(prev => !prev)}>
                            { showPassword ? <Visibility /> : <VisibilityOff />  }
                        </div>
                    </MDBInputGroup>
                    {errors?.password && <small className='error-text'>{errors.password}</small>}

                    <MDBInputGroup className='password-group mt-4' size='lg'>                        
                        <input
                            placeholder='Confirm Password'                            
                            type={showConfirmPassword ? 'text' : 'password'}
                            className='form-control'
                            onChange={handleConfirmPassword}
                            required
                        />
                        <div  onClick={() => setShowConfirmPassword(prev => !prev)}>
                            { showConfirmPassword ? <Visibility /> : <VisibilityOff />   }
                        </div>
                    </MDBInputGroup>
                    {errors.confirmPassword && <p className='error-text'>{errors.confirmPassword}</p>}

                    {screenWidth <= 799 && 
                    (<p className='mt-3 text-center'>
                        Already have an account? 
                        <Link to="/restaurant-portal/signin" style={{ color: '#FFAE0A'}}>
                            &nbsp;Sign in
                        </Link>
                    </p>)
                    }

                    <MDBBtn 
                        className='next-btn mt-4' 
                        size='lg' type='submit'>
                           {loading ?
                            <Loader
                                height="27px"
                                width="27px"
                                borderTopColor='#FFAE0A'
                                borderTopWidth='5px'
                                borderWidth='5px'
                            /> :  <>NEXT &rarr;</>}
                            </MDBBtn>

                   
                </form>
            </div>
        </AuthLayout>
    )
}


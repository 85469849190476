import {useEffect} from "react";
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';

// import { Reviews } from "../models";
import "../styling/reviews.scss";


function Reviews({ reviews }: any) {
    
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, [])

    return(
        <section id="reviews"  className="review-container">
            <h2>OUR REVIEWS</h2>
            <div className='reviews-box-container'>                    
                {/* <HorizontalScroll > */}
                {reviews.map((review: any) => (
                    <div className="review-box" key={review.id}>
                        <p>{review.text}</p>
                        <p className="author">{review.author}</p>
                    </div>
                ))}
                {/* </HorizontalScroll> */}
            </div>
        </section>
    )
} 


export default Reviews;
import React from "react";

type LoaderProps = {
  height: string,
  width: string
  borderWidth: string,
  borderTopWidth: string,
  borderTopColor: string
}

const Loader = ({ height, width, borderTopWidth, borderTopColor, borderWidth }: LoaderProps) => {
  return (
    <div className="loader" style={{ 
      height, 
      width,
      borderWidth,
      borderTopWidth,
      borderTopColor
     }}></div>
  );
}

export default Loader;
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthStatusProps } from "../../config";
import { RootState } from "../../../app/store";

const initialState: AuthStatusProps = {
    isActivated: false
}

export const authStatusSlice = createSlice({
    name: 'restaurantAuthStatus',
    initialState,
    reducers: {
        setActivated: (state, action:PayloadAction<{ isActivated: boolean}>) => {
            state.isActivated = action.payload.isActivated
        }
    }
})


export const selectRestaurantAuthStatus = (state: RootState) => state.restaurantAuthStatus
export const { setActivated } = authStatusSlice.actions;
export default authStatusSlice.reducer

import { Box, FormControl, TextField, } from '@mui/material';
import {useEffect} from "react";
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';
import contactImg from "../../../assets/images/landing-page/contact-img.svg";
import "../styling/contact-us.scss";




function ContactUs(){
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);


    

    return(
        <section>
            <div id='contact' className="contact-section">
                <h2>CONTACT US</h2>
                <div className='contact-info-container'>
                    <div className='grid-1'>
                        <h3>Contact information</h3>
                        <p>Fill out the form and our team will get back to you within 24 hours</p>
                        <div className="img">
                            <img src={contactImg} alt={'contact us'} />
                        </div>
                    </div>
                    <div className='grid-2'>
                        <Box
                            component="form"
                            sx={{m: 1, display: 'flex', flexWrap: 'wrap', gap: '20px', minWidth: '250px',}}
                            noValidate
                            autoComplete="off"
                            >
                            <FormControl fullWidth sx={{m: 1}}> 
                                <div className='name-fields' style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>  
                                    <TextField 
                                        id="First-name"
                                        label="First name" 
                                        variant="outlined"
                                        type="text"
                                        sx={{width: '47%'}} />
                                    <TextField 
                                        id="last-name" 
                                        label="Last name" 
                                        variant="outlined" 
                                        type='text'
                                        sx={{width: '47%'}} />                
                                </div>                                     
                            </FormControl>
                            <FormControl fullWidth sx={{m: 1}}>  
                                <div className='email-phone-fields' style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                    <TextField 
                                        id="email" 
                                        label="Email" 
                                        variant="outlined" 
                                        type="email"
                                        className='email'
                                        sx={{width: '47%'}} />
                                    <TextField 
                                        id="phone" 
                                        label="Phone" 
                                        variant="outlined" 
                                        type="tel" 
                                        className='phone'
                                        sx={{width: '47%'}} />
                                </div>
                            </FormControl> 
                            <FormControl fullWidth sx={{m: 1}}>                               
                                <TextField 
                                    id="subject" 
                                    label="Subject" 
                                    variant="outlined" 
                                    type="text" />
                            </FormControl>
                            <FormControl fullWidth sx={{m: 1}}>        
                                <TextField 
                                    id="message"
                                    label="Message"
                                    variant="outlined" 
                                    multiline
                                    rows={10} 
                                />
                            </FormControl>
                            <FormControl sx={{m: 1}} className='btn-container'> 
                                <button 
                                    type="submit" 
                                    className='submit-btn'>SUBMIT
                                </button>
                            </FormControl>
                        </Box>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default ContactUs;
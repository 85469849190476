import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

import LogoNoText from '../../assets/images/logo-no-text.svg';
import "./authLayout.scss";

type Props = {
    children: React.ReactNode;
    headingText: string;
    subText: string;
    btnText: string;
    btnPath: string;
}

export const AuthLayout: React.FC<Props> = ({
     headingText, 
     subText, 
     btnText, 
     children,
     btnPath
    }) => {
    return(
        <MDBContainer fluid={true}  breakpoint="md" className='layout'>
            <MDBRow>
                <MDBCol size="4" className='d-none d-md-block layout-left-pane'>    
                    <Link to={'/'} className='logo-link'>
                        <img src={LogoNoText} alt='peakreach logo' className='logo' />
                    </Link> 
                    <div className='left-pane-welcome-note'>
                        <h1>{headingText}</h1>
                        <p>{subText}</p>
                        <div className="authBtn"><Link to={btnPath}>{btnText}</Link></div>
                    </div>
                </MDBCol>
                <MDBCol size="12" md={8}>
                    {children}
                </MDBCol>
            </MDBRow>                         
        </MDBContainer>
    )
}


import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { GOOGLE_MAPS_API } from "../utils/config";

type AddressInputProps = {
  placeholder?: string,
  type?: 'text',
  onLoad?: any,
  onPlaceChanged: any,
  required?: boolean,
  className?: string
}

// co-ordinates and bounds for Abuja region 
const initialBounds = {
  south: 8.948444,
  west: 6.898310,
  north: 9.198885,
  east: 7.495083,
};

const AddressInput = (props: AddressInputProps) => {
    const { placeholder, type, onLoad, onPlaceChanged, required, className } = props;
   
    return(
      <LoadScript googleMapsApiKey={GOOGLE_MAPS_API} region="NG"  libraries={["places"]}> 
          <StandaloneSearchBox
              onLoad={onLoad}
              onPlacesChanged={onPlaceChanged}
              bounds={initialBounds}
          >
              <input
                type={type}
                className={className}
                placeholder={placeholder}
                required={required}
              />
          </StandaloneSearchBox>
      </LoadScript>
    )
}

export default AddressInput;
import { NavLink } from "react-router-dom";
import "../styling/jumbotron.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function Jumbotron() {
  useEffect(() => {
    AOS.init();
    // AOS.refresh();
  }, []);

  return (
    <section className="jumbotron">
      <div>
        <h1 data-aos="fade-down" data-delay="500">
          Your One-Stop Solution for Food Delivery and Logistics
        </h1>
        <p>Delivering food and logistics with precision and speed</p>
        <NavLink to="/get-started">GET STARTED</NavLink>
      </div>
    </section>
  );
}

export default Jumbotron;

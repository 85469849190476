import { configureStore } from "@reduxjs/toolkit";
import {
  restaurantAuthApi,
  restaurantApi,
} from "../features/services/restaurant/service";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import restaurantAuthReducer from "../features/slice/restaurant/authSlice";
import restaurantAuthStatusReducer from "../features/slice/restaurant/authStatusSlice";
import restaurantNotificationReducer from "../features/slice/restaurant/notificationSlice";

export const store = configureStore({
  reducer: {
    restaurantAuth: restaurantAuthReducer,
    restaurantAuthStatus: restaurantAuthStatusReducer,
    restaurantNotifications: restaurantNotificationReducer,
    [restaurantAuthApi.reducerPath]: restaurantAuthApi.reducer,
    [restaurantApi.reducerPath]: restaurantApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      restaurantAuthApi.middleware,
      restaurantApi.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
setupListeners(store.dispatch);

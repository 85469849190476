import React, { useState, useRef, useEffect, CSSProperties } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@mui/icons-material";
import { FiUser } from "react-icons/fi";
import { ImSpoonKnife } from "react-icons/im";
import { BsCardChecklist, BsFillCaretLeftFill } from "react-icons/bs";
import { TbStars } from "react-icons/tb";
import { IoMdNotifications } from "react-icons/io";
import { toast } from "react-toastify";
import { io } from "socket.io-client";

import LogoNoText from "../../assets/images/logo-no-text.svg";
import "./pageLayout.scss";
import useOnClickOutside from "../../utils/methods/ui-hooks/onOutsideClick";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { removeUser } from "../../features/slice/restaurant/authSlice";
import { selectRestaurantAuthStatus } from "../../features/slice/restaurant/authStatusSlice";

type Props = {
  children: React.ReactNode;
};

type NavProps = {
  id: number;
  title: string;
  icon: any;
  path: string;
  dropdown?: boolean;
};

const nav_links: Array<NavProps> = [
  {
    id: 0,
    title: "Dashboard",
    icon: <BsCardChecklist />,
    path: "/restaurant-portal/dashboard",
  },
  {
    id: 1,
    title: "Menu Manager",
    icon: <ImSpoonKnife />,
    path: "/restaurant-portal/menu-manager",
  },
  {
    id: 2,
    title: "Customer reviews",
    icon: <TbStars />,
    path: "/restaurant-portal/reviews",
  },
  // {
  //   id: 3,
  //   title: "Notifications",
  //   icon: <IoMdNotifications />,
  //   path: "/restaurant-portal/notifications",
  // },
  {
    id: 4,
    title: "Restaurant profile",
    icon: <FiUser />,
    path: "/restaurant-portal/profile",
  },
];
// /restaurant-portal/profile

export const PageLayout: React.FC<Props> = ({ children }: any) => {
  const [dropdownToggle, setDropdownToggle] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [activationStatus, setActivationStatus] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { isActivated } = useAppSelector(selectRestaurantAuthStatus);
  const navigate = useNavigate();
  const [toggleLogout, setToggleLogout] = useState(false);

  useEffect(() => {
    if (!isActivated) {
      setActivationStatus(false);
    } else {
      setActivationStatus(true);
    }
  }, [isActivated]);

  useOnClickOutside(dropdownRef, () => setDropdownToggle(false));

  const handleLogout = (e: any) => {
    e.preventDefault();
    dispatch(removeUser());
    toast.success("logout successful");
    navigate("/restaurant-portal/signin");
  };

  // const activationStyle = {
  //   pointerEvents: !activationStatus ? 'auto' as CSSProperties["pointerEvents"] : 'none' as CSSProperties["pointerEvents"],
  //   // color: activationStatus ? 'inherit' : 'gray'
  // }

  return (
    <div className="layout-container">
      {/* restaurant portal side navbar for web view  */}
      <nav className="restaurant-nav">
        <div className="nav-container">
          <img src={LogoNoText} alt={"logo"} />
          <ul>
            {/* loop through all navlinks of the sidebar for the restaurant portal */}
            {nav_links.map((nav_link, i) => (
              <li key={nav_link.id}>
                <NavLink
                  to={nav_link.path}
                  title={nav_link.title}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  end
                >
                  {nav_link.icon}
                </NavLink>
              </li>
            ))}
          </ul>
          <div className="btn-container">
            <button onClick={() => setToggleLogout(true)}>
              <LogoutOutlined fontSize="medium" />
            </button>
          </div>
        </div>
      </nav>

      {/* restaurant bottom navbar for mobile screen view  */}
      <div className="restaurant-bottom-nav-mobile">
        <div className="nav-container-bottom-mobile">
          <ul>
            {nav_links.map((nav_link, i) => (
              <li key={nav_link.id}>
                {/* {nav_link.dropdown ? (
                  <div
                    onClick={() => setDropdownToggle((prevState) => !prevState)}
                    className={`${ dropdownToggle ? "nav-btn-active" : "nav-btn" }`}
                  >
                    {nav_link.icon}
                  </div>
                ) : ( */}
                <NavLink
                  to={nav_link.path}
                  title={nav_link.title}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  {nav_link.icon}
                </NavLink>
                {/* )} */}
              </li>
            ))}
          </ul>
          <div className="btn-container">
            <button onClick={() => setToggleLogout(true)}>
              <LogoutOutlined fontSize="medium" />
            </button>
          </div>
        </div>
      </div>

      <main>{children}</main>
      {/* {dropdownToggle && (
        <div
          className="profile-dropdown"
          //  ref={dropdownRef}
        >
          <ul>
            <li>
              <NavLink
                to="/restaurant-portal/profile"
                onClick={handleProfileLinkClick}
                style={activationStyle}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                View Profile{" "}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/restaurant-portal/account-activation"
                onClick={handleProfileLinkClick}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Account Activation
              </NavLink>
            </li>
            <li >Something else here</li> */}
      {/*</ul>
          <BsFillCaretLeftFill className="caret-left" color="#292D32" />
        </div>
      )} */}
      {toggleLogout && (
        <div className="logout-modal">
          <div className="logout-modal-sub">
            <p>Do you wish to logout ?</p>
            <div className="logout-btn-container">
              <div className="logout-btn" onClick={handleLogout}>
                Logout
              </div>
              <div
                className="cancel-logout-btn"
                onClick={() => setToggleLogout(false)}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

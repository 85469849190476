import { useEffect, useState } from "react";


const useWindowWidth = () => {
    const [width, setWidth] = useState(0)
    
    useEffect(() => {
      function handleResize() { setWidth(window.innerWidth) }
      
      window.addEventListener("resize", handleResize);

      handleResize()
      
      // on Component unmount
      return () => { 
        window.removeEventListener("resize", handleResize)
      }
    }, [setWidth]);

    return width;
}

export default useWindowWidth;
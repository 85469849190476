import LandingPageLayout from "../../components/landing-page/LandingPageLayout";
import { FooterSection } from "./components";
import "./styling/privacy-policy.scss";

function PrivacyPolicy() {
  return (
    <LandingPageLayout inverse={true}>
      <section className="privacy-policy-container">
        {/* <h1>Acceptable Use Policy</h1> */}

        <div className="content-container">
          <section className="privacy-policy-section">
            <h1>Peakreach Privacy Policy</h1>
            <p>
              <strong>Last Updated:</strong> July 20th, 2024
            </p>
            <p>
              <strong>Version:</strong> 1.0
            </p>

            <p>
              Welcome to Peakreach! At Peakreach, we are committed to protecting
              your privacy and safeguarding your personal information. This
              Privacy Policy outlines how we collect, use, disclose, and protect
              your data when you use our services, website, and related
              applications.
            </p>

            <h2>1. Information We Collect</h2>
            <p>
              <strong>1.1. Personal Information:</strong> We may collect
              personal information such as your name, photo, email address,
              postal address, phone number, and other information you provide
              when you use our services.
            </p>
            <p>
              <strong>1.2. Usage Data:</strong> We collect information on how
              you interact with our website and services, including IP address,
              browser type, operating system, referring URLs, and page views.
            </p>
            <p>
              <strong>1.3. Location Data:</strong> Your location data is used to
              provide location-based services and features. This data may be
              stored locally or on our servers, depending on the functionality
              of the app. We do not share your location data with third parties.
              With your permission, we will obtain your location through GPS,
              and other sensors that provide necessary information for the
              provision of our Services.
            </p>
            <p>
              <strong>1.4. Camera and Audio Usage:</strong> Our applications
              require access to your device's camera and microphone for the
              following purposes:
              <ul>
                <li>
                  <strong>Camera:</strong> Capturing photos directly from the
                  agent app.
                </li>
                <li>
                  <strong>Microphone:</strong> Recording audio, enabling voice
                  calls, and other audio features.
                </li>
              </ul>
              When you use features that require the camera or microphone, we
              will request your explicit permission to access your device's
              camera and microphone. You have the option to grant or deny this
              permission. If you choose to deny access, certain functionalities
              of the app may be limited or unavailable.
            </p>
            <p>
              <strong>1.5. Payment Data:</strong> We may collect data necessary
              to process your payment if you choose to make purchases, such as
              your debit/credit card details, or account number. All payment
              data collected is stored by us.
            </p>

            <h2>2. How We Use Your Information</h2>
            <p>
              <strong>2.1. Providing Services:</strong> We use your personal
              information to provide and improve our services, communicate with
              you, and respond to your requests.
            </p>
            <p>
              <strong>2.2. Marketing:</strong> We may use your information to
              send you promotional materials, newsletters, or updates related to
              our services. You can opt out of these communications at any time.
            </p>
            <p>
              <strong>2.3. Legal Compliance:</strong> We may process your
              personal information to comply with legal obligations, resolve
              disputes, and enforce our agreements.
            </p>

            <h2>3. Sharing Your Information</h2>
            <p>
              <strong>3.1. Third Parties:</strong> We may share your information
              or data collected with third-party service providers who assist us
              in providing our services. We require these third parties to
              maintain the confidentiality of your information and comply with
              data protection regulations. They will not share your personal
              information with any organization apart from us.
            </p>
            <p>
              <strong>3.2. Legal Requirements:</strong> We may disclose your
              information when required by law or to protect our rights,
              privacy, safety, or property.
            </p>

            <h2>
              4. How can you review, update or delete data we collect from you?
            </h2>
            <p>
              You have the right to request access to the personal information
              we collect from you, details about how we have processed it,
              correct inaccuracies, or delete your personal information. You may
              also have the right to withdraw your consent to our processing of
              your personal information. These rights may be limited in some
              circumstances by applicable law. To request to review, update, or
              delete your personal information, please reach us at
              Peakreachfd@gmail.com.
            </p>

            <h2>5. Cookies and Tracking Technologies</h2>
            <p>
              We use cookies and similar tracking technologies to enhance your
              user experience, analyze website usage, and understand user
              interactions with our services. You can manage your cookie
              preferences in your browser settings.
            </p>

            <h2>6. Data Security</h2>
            <p>
              We employ reasonable security measures to protect your personal
              information from unauthorized access, disclosure, alteration, or
              destruction. However, no method of data transmission over the
              internet or electronic storage is entirely secure, and we cannot
              guarantee its absolute security.
            </p>

            <h2>7. Your Choices and Rights</h2>
            <p>
              You have the right to access, correct, or delete your personal
              information. You may also opt out of receiving promotional
              communications. For any requests related to your data, please
              contact us at [Contact Email].
            </p>

            <h2>8. Links to Other Websites</h2>
            <p>
              Our services may contain links to external websites or services
              that we do not control. We are not responsible for the privacy
              practices or content of these websites. We encourage you to review
              the privacy policies of these third-party sites.
            </p>

            <h2>9. Changes to this Privacy Policy</h2>
            <p>
              We may update this Privacy Policy to reflect changes in our data
              practices or legal requirements. The latest version of the policy
              will be available on our website with the effective date.
            </p>

            <h2>10. Contact Information</h2>
            <p>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at:
            </p>
            <p>Phone number: 08128224769</p>
            <p>
              <strong>Last Updated:</strong> July 20th, 2024
            </p>
            <p>
              <strong>Version:</strong> 1.0
            </p>
          </section>

          {/* Old Privacy policy */}
          {/* <section className="privacy-policy-section">
            <h1> Privacy Policy</h1>
            <p>
              <strong>Last Updated:</strong> OCT,21ST 2023
            </p>
            <p>
              <strong>Version:</strong> 1.0
            </p>
            <p>
              Welcome to Peakreach! At Peakreach, we are committed to protecting
              your privacy and safeguarding your personal information. This
              Privacy Policy outlines how we collect, use, disclose, and protect
              your data when you use our services, website, and related
              applications.
            </p>

            <h2>1. Information We Collect</h2>
            <p>
              <strong>1.1. Personal Information:</strong> We may collect
              personal information such as your name, email address, postal
              address, phone number, and other information you provide when you
              use our services.
            </p>
            <p>
              <strong>1.2. Usage Data:</strong> We collect information on how
              you interact with our website and services, including IP address,
              browser type, operating system, referring URLs, and page views.
            </p>

            <h2>2. How We Use Your Information</h2>
            <p>
              <strong>2.1. Providing Services:</strong> We use your personal
              information to provide and improve our services, communicate with
              you, and respond to your requests.
            </p>
            <p>
              <strong>2.2. Marketing:</strong> We may use your information to
              send you promotional materials, newsletters, or updates related to
              our services. You can opt out of these communications at any time.
            </p>
            <p>
              <strong>2.3. Legal Compliance:</strong> We may process your
              personal information to comply with legal obligations, resolve
              disputes, and enforce our agreements.
            </p>

            <h2>3. Sharing Your Information</h2>
            <p>
              <strong>3.1. Third Parties:</strong> We may share your information
              with third-party service providers who assist us in providing our
              services. We require these third parties to maintain the
              confidentiality of your information and comply with data
              protection regulations.
            </p>
            <p>
              <strong>3.2. Legal Requirements:</strong> We may disclose your
              information when required by law or to protect our rights,
              privacy, safety, or property.
            </p>

            <h2>4. Cookies and Tracking Technologies</h2>
            <p>
              We use cookies and similar tracking technologies to enhance your
              user experience, analyze website usage, and understand user
              interactions with our services. You can manage your cookie
              preferences in your browser settings.
            </p>

            <h2>5. Data Security</h2>
            <p>
              We employ reasonable security measures to protect your personal
              information from unauthorized access, disclosure, alteration, or
              destruction. However, no method of data transmission over the
              internet or electronic storage is entirely secure, and we cannot
              guarantee its absolute security.
            </p>

            <h2>6. Your Choices and Rights</h2>
            <p>
              You have the right to access, correct, or delete your personal
              information. You may also opt out of receiving promotional
              communications. For any requests related to your data, please
              contact us at{" "}
              <a href="mailto:your-email@example.com">your-email@example.com</a>
              .
            </p>

            <h2>7. Links to Other Websites</h2>
            <p>
              Our services may contain links to external websites or services
              that we do not control. We are not responsible for the privacy
              practices or content of these websites. We encourage you to review
              the privacy policies of these third-party sites.
            </p>

            <h2>8. Changes to this Privacy Policy</h2>
            <p>
              We may update this Privacy Policy to reflect changes in our data
              practices or legal requirements. The latest version of the policy
              will be available on our website with the effective date.
            </p>

            <h2>9. Contact Information</h2>
            <p>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at:
            </p>
            <p>
              <strong>Phone number:</strong> 08128224769, 07042744334
            </p>
            <p>
              <strong>Last Updated:</strong> OCT,21ST 2023
            </p>
            <p>
              <strong>Version:</strong> 1.0
            </p>
          </section> */}
        </div>
      </section>
      <FooterSection />
    </LandingPageLayout>
  );
}

export default PrivacyPolicy;

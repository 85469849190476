import LandingPageLayout from "../../components/landing-page/LandingPageLayout";
import { FooterSection } from "./components";
import "./styling/meal-categories.scss";

function MealsCategories() {
  return (
    <LandingPageLayout inverse={true}>
      <section className="meals-categories-container">
        <h4>Meal Categories</h4>
        <div className="container">
          <div className="category">
            <h2>Nigerian Classics</h2>
            <ul>
              <li>Jollof Rice</li>
              <li>Fried Rice</li>
              <li>Porridge Beans</li>
              <li>Yam and Egg Sauce</li>
              <li>Suya</li>
              <li>Moi Moi</li>
            </ul>
          </div>

          <div className="category">
            <h2>Breakfast Delights</h2>
            <ul>
              <li>Akara (Bean cakes)</li>
              <li>Toasts</li>
              <li>Yamarita (Yam Fries)</li>
            </ul>
          </div>

          <div className="category">
            <h2>Soups & Swallow</h2>
            <ul>
              <li>Egusi</li>
              <li>Okra</li>
              <li>Seafood</li>
              <li>Ewedu</li>
              <li>Afang</li>
              <li>Eba</li>
              <li>Fufu</li>
              <li>Pounded Yam</li>
            </ul>
          </div>

          <div className="category">
            <h2>Rice & Pasta</h2>
            <ul>
              <li>Jollof Rice</li>
              <li>Fried Rice</li>
              <li>Ofada Rice</li>
              <li>Coconut Rice</li>
              <li>Spaghetti</li>
              <li>Macaroni</li>
            </ul>
          </div>

          <div className="category">
            <h2>Grills & Roasts</h2>
            <ul>
              <li>Suya</li>
              <li>Grilled Chicken</li>
              <li>Grilled Fish</li>
              <li>Roasted Plantain</li>
            </ul>
          </div>

          <div className="category">
            <h2>Snacks & Bites</h2>
            <ul>
              <li>Puff-Puff</li>
              <li>Meat Pie</li>
              <li>Donuts</li>
              <li>Samosa</li>
            </ul>
          </div>

          <div className="category">
            <h2>Desserts</h2>
            <ul>
              <li>Fruit Salad</li>
              <li>Plantain Pudding</li>
              <li>Fruit Cakes</li>
            </ul>
          </div>

          <div className="category">
            <h2>Urban Eats</h2>
            <ul>
              <li>Pizza</li>
              <li>Burgers</li>
              <li>Sandwiches</li>
              <li>Shawarma</li>
              <li>Chicken & Chips</li>
              <li>French Fries</li>
            </ul>
          </div>

          <div className="category">
            <h2>Beverages & Water</h2>
            <ul>
              <li>Soft Drinks (Coca-Cola, Fanta, etc.)</li>
              <li>Fruit Juice</li>
              <li>Tea</li>
              <li>Milkshakes</li>
              <li>Smoothies</li>
              <li>Zobo</li>
              <li>Table Water</li>
            </ul>
          </div>

          <div className="category">
            <h2>International Options</h2>
            <ul>
              <li>Chinese Food</li>
              <li>Sushi</li>
              <li>Italian Dishes</li>
            </ul>
          </div>

          <div className="category">
            <h2>Special Diets</h2>
            <ul>
              <li>Vegan</li>
              <li>Vegetarian</li>
              <li>Gluten-Free</li>
            </ul>
          </div>

          <div className="category">
            <h2>Add-Ons</h2>
            <ul>
              <li>Dodo (Fried Plantains)</li>
              <li>Proteins (Chicken, Beef, Goat Meat, Turkey)</li>
              <li>Moi Moi</li>
              <li>Coleslaw</li>
            </ul>
          </div>
        </div>
      </section>

      <FooterSection />
    </LandingPageLayout>
  );
}

export default MealsCategories;

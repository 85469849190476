import React from "react";
import { 
  Step, 
  Stepper, 
  StepLabel,
} from "@mui/material";

type StepperProps  = {
    steps: Array<{ label: string, }>,
    step: any,
    showLastStep?: boolean
}


const stepperStyle  = {
  "& .Mui-active": {  
    "&.MuiStepIcon-root": { color: "#FFAE0A" }
  },
  "& .Mui-completed": {
    "&.MuiStepIcon-root": { color: "#FFAE0A" } 
  }  
}


export function Stages({ steps, step, showLastStep = true }: StepperProps ){

  return (
    <div style={{ minWidth: '350px', maxWidth: '70%', width: '70%', margin: '0 auto'}}>
      <Stepper 
        activeStep={step} 
        sx={stepperStyle} 
        alternativeLabel>
          {steps?.map((label: any, index: number) => {
            if (!showLastStep && index === steps?.length - 1) return null;
            return (
              <Step key={index}>
                <StepLabel>{label.label}</StepLabel>
              </Step>
            );
          })}
      </Stepper>
    </div>
  );
}


import { ReactNode, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from '../../assets/images/landing-page/pr-logo.svg';
import logoInverse from '../../assets/images/logo_.svg';
import logoNoText from '../../assets/images/logo-no-text.svg';
import menu from '../../assets/images/landing-page/menu.svg';
import "./index.scss";
import { MdClose } from "react-icons/md";

interface LandingPageProps {
    children: ReactNode,
    inverse?: boolean
}


function LandingPageLayout({ children, inverse }: LandingPageProps) {
    const [toggle, setToggle] = useState(false);
    const navItemsStyle = inverse ? { background: 'white'} : { background: '#0A0A0A'};
    const listStyle = inverse ? { color: '#0A0A0A'} : { color: 'white'};

    return (
        <div className="layout-container">
            <nav>
                <div className="nav-items" style={navItemsStyle}>
                    <NavLink to="/">
                        {inverse ? <img src={logoInverse} alt="logo" className="logo-inverse" />  : <img src={logo} alt="logo" className="logo" /> }
                        <img src={logoNoText} alt="logo" className="logo-no-text" />
                    </NavLink>

                    <ul>
                        <li><a style={listStyle} href="#about">ABOUT</a></li>
                        <li><a style={listStyle} href="#services">SERVICES</a></li>
                        <li><a style={listStyle} href="#reviews">REVIEWS</a></li>
                        <li><a style={listStyle} href="#contact">CONTACT</a></li>
                    </ul>
                    <div className="request_menu">
                        <NavLink className='request-rider-btn' to=''>REQUEST A RIDER</NavLink>
                        <img src={menu} alt="menu" className="menu" onClick={() => setToggle(true)} />
                    </div>
                </div>
            </nav>
            <div className="layout-main">{ children }</div>
            { toggle && 
             <div className="overlay">
                <div className="drop-down">
                    <div className="close-btn">  
                        <img src={logoNoText} alt="logo" />
                        <MdClose  onClick={() => setToggle(false)} />
                    </div>
                    <div className='request-rider-btn-dropdown'>
                        <NavLink to=''>REQUEST A RIDER</NavLink>
                    </div>
                </div>
             </div>
            }
        </div>
    )
}

export default LandingPageLayout;
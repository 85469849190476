import foodServiceImg from "../../../assets/images/landing-page/services_.svg";
import logisticServiceImg from "../../../assets/images/landing-page/service__.svg";
import food from "../../../assets/images/landing-page/food_d.svg";
import logistics from "../../../assets/images/landing-page/logistics.svg";
import "../styling/service.scss";

function Service() {
    return (
        <section id="services" className="service-container">
            <div>
                <h2>OUR SERVICES</h2>
                <div className="grid-container">
                    <div className="grid-1">                           
                        <div className="text">
                            <h3 className="header">
                                <img src={food} alt="food delivery" /> 
                                <span>Food Delivery Services</span>
                            </h3>
                            <p> At Peakreach, we understand that great food should be delivered promptly and with care. We take pride in our food delivery services, offering you a seamless culinary experience.</p><br />
                            <ul>
                                <li>
                                    <b>Divine Cuisine:</b> Enjoy a wide selection of cuisine from local favorites to global delights. Our network of partner restaurants ensures you have an array of culinary options to choose from.
                                </li><br />
                                <li>
                                    <b>Speedy Delivery:</b> We prioritize speed without compromising on quality. Your hot and fresh meal arrives at your doorstep with precision and punctuality
                                </li><br />
                                <li>
                                    <b>User-Friendly App:</b> Ordering is a breeze with our user-friendly app. Browse menus, track orders in real-time, and enjoy exclusive deals
                                </li><br />
                                <li>
                                    <b>Contactless Delivery:</b> We prioritize safety and offer contactless delivery options, ensuring your peace of mind with every order
                                </li>
                            </ul>
                        </div>
                        <div className="img">
                            <img src={foodServiceImg} alt="food" />
                        </div>
                    </div>

                    <div className="grid-2"> 
                        <div className="img">
                            <img src={logisticServiceImg} alt="about" />
                        </div>                        
                        <div className="text">
                            <h3 className="header">
                                <img src={logistics} alt="logistics" /> 
                                <span>Logistics Services</span>
                            </h3>
                            <p> Peakreach is your go-to logistics partner, offering comprehensive solutions for both individuals and businesses. We specialize in seamless item moving and same-day delivery. Our expertise extends to catering to the unique needs of businesses, ensuring smooth operations and reliable deliveries.</p><br />
                            <ul>
                                <li>
                                    <b>Item Moving:</b> For everyday users, we specialize in moving items, from packages to personal belongings. Need to transport something quickly? We've got you covered.
                                </li><br />
                                <li>
                                    <b>Same Day Delivery:</b> Enjoy the convenience of same-day delivery for both personal and business needs. We ensure your items reach their destination promptly
                                </li><br />
                                <li>
                                    <b>Easy to Use App:</b> Our intuitive app simplifies the entire process. Request a pickup, track your items, and receive delivery updates – all from the palm of your hand
                                </li><br />
                                <li>
                                    <b>Business Deliveries:</b> Businesses rely on us to handle their logistics efficiently. From timely deliveries to bulk shipments, we tailor our services to meet your business requirements
                                </li>
                            </ul>
                        </div>
                    </div>
                
                </div>
            </div>
        </section>
    )
}

export default Service;
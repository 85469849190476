/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from "react";
import { AddAPhotoOutlined } from "@mui/icons-material";
import { MDBContainer } from "mdb-react-ui-kit";
import { Link, useNavigate } from "react-router-dom";
import { PageLayout } from "../../../components/restaurant-portal/PageLayout";
import {
  useCreateMealMutation,
  useGetMealsCategoriesQuery,
  useUploadMealImageMutation,
} from "../../../features/services/restaurant/service";
import { toast } from "react-toastify";
import { FormControl, TextField } from "@mui/material";
import Loader from "../../../components/loader";
import axios from "axios";
// import FormData from "form-data";

// const menu_list = [
//   {
//     category: "combos",
//     menu_list: [
//       {
//         id: "jksdkgsfodh",
//         image: "",
//         name: "sapa meal",
//         price: 700,
//         description: "Native rice & beef",
//       },
//       {
//         id: "klhhjkgho",
//         image: "",
//         name: "Sapa Meal",
//         price: 700,
//         description: "Native rice & beef",
//       },
//       {
//         id: "lkguiogk",
//         image: "",
//         name: "Sapa Meal",
//         price: 700,
//         description: "Native rice & beef",
//       },
//       {
//         id: "",
//         image: "",
//         name: "",
//         price: 700,
//         description: "",
//       },
//     ],
//   },
//   {
//     category: "combos",
//     menu_list: [
//       {
//         id: "",
//         image: "",
//         name: "",
//         price: 700,
//         description: "",
//       },
//     ],
//   },
//   {
//     category: "combos",
//     menu_list: [
//       {
//         id: "",
//         image: "",
//         name: "",
//         price: 700,
//         description: "",
//       },
//     ],
//   },
// ];

export const RestaurantAddMenu = () => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [uploadedImage, setUploadedImage] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState<number | string>("");
  const [description, setDescription] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  // const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | any>(null);
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);

  //////////////////////////////////////////////
  // All Queries and mutations
  const [uploadMealImage, { uploadStatus, isUploadingImg, errorUploadingImg }] =
    useUploadMealImageMutation({
      selectFromResult: ({ data, isLoading, error }) => ({
        uploadStatus: data,
        isUploadingImg: isLoading,
        errorUploadingImg: error,
      }),
    });

  const {
    data: categories,
    isLoading: loading_categories,
    error: error_fetching_categories,
  } = useGetMealsCategoriesQuery();

  const [
    createMeal,
    {
      data: createMealSuccess,
      isLoading: creating_meal,
      error: error_creatingMeal,
    },
  ] = useCreateMealMutation();

  //////////////////////////////////////////////
  ///Functions
  const showFileInput = () => hiddenFileInput.current?.click();

  // to firstly upload image to cloudinary
  const authToken = sessionStorage.getItem("token");

  ///////////////////////////////// To upload Image to cloudinary
  const handleUpload = () => {
    setUploading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/image-upload`,
        { image: selectedImage },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res: any) => {
        setUploading(false);
        setUploadedImage(res?.data?.url);
        toast.success("Image uploaded successfully");
      })
      .catch((err) => {
        setUploading(false);
        toast.error("Error uploading image");
      });
  };

  // Initial image upload function
  // const handleUpload = async () => {
  //   if (!selectedImage) return;

  //   const formData = new FormData();
  //   // formData.append("name", "image");
  //   formData.append("image", selectedImage);
  //   // formData.append('Content-Type', selectedImage.type);
  //   try {
  //     await uploadMealImage(formData).unwrap();
  //   } catch (error) {
  //     toast.error("Error! Ensure file size is less than 250kb");
  //   }
  // };

  ////////////////////////// To preview image

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (name && price && description && selectedCategory && uploadedImage) {
      createMeal({
        name,
        price: {
          amount: price,
          currency_code: "NGN",
        },
        description,
        image_url: uploadedImage,
        meal_category: selectedCategory,
        is_in_stock: true,
      });
    } else {
      toast.error("All input fields are required");
    }
  };

  //////////////////////////////////////////////

  /// useEffects
  useEffect(() => {
    if (selectedImage) {
      handleUpload();
    }
  }, [selectedImage]);

  useEffect(() => {
    setUploadedImage(uploadStatus?.url);
  }, [uploadStatus, errorUploadingImg]);

  useEffect(() => {
    if (createMealSuccess) {
      toast.success("Meal added successfully");
      navigate("/restaurant-portal/menu-manager");
    }
    if (error_creatingMeal) {
      // toast.error(error_creatingMeal?.data?.error)
      toast.error("Error creating meal! Try again");
    }
  }, [createMealSuccess, error_creatingMeal]);

  return (
    <PageLayout>
      <MDBContainer fluid={true} breakpoint="md" className="add-menu-container">
        <h1>
          <Link to="/restaurant-portal/menu-manager" style={{ color: "black" }}>
            &larr; &nbsp;
          </Link>
          Add New Meal
        </h1>
        <section>
          <form onSubmit={handleSubmit}>
            <div className="photo-upload">
              {!uploadedImage && !uploading ? (
                <AddAPhotoOutlined fontSize="large" onClick={showFileInput} />
              ) : !uploadedImage && uploading ? (
                <Loader
                  height="27px"
                  width="27px"
                  borderTopColor="#FFAE0A"
                  borderTopWidth="5px"
                  borderWidth="5px"
                />
              ) : (
                <img
                  src={uploadedImage}
                  alt=""
                  width="100%"
                  height="250px"
                  onClick={showFileInput}
                />
              )}

              {/* The one below was used for testing */}
              {/* {selectedImage ? (
                <img
                  src={selectedImage}
                  alt=""
                  width="100%"
                  height="250px"
                  onClick={showFileInput}
                />
              ) : (
                <AddAPhotoOutlined fontSize="large" onClick={showFileInput} />
              )} */}
            </div>
            <div className="label-texts">
              <p className="first">
                This image will be displayed to the customers
              </p>
              <p className="second">Recommended aspect ratio: 4:3</p>
            </div>
            <input
              type="file"
              className="hidden-input"
              ref={hiddenFileInput}
              accept="image/*"
              //comment back to return initial functionalities
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                e.target?.files && setSelectedImage(e.target?.files[0])
              }

              // onChange={(event: any) => handleImageChange(event)}
            />
            <div className="inputs-container">
              <FormControl fullWidth style={{ marginBottom: "1.3rem" }}>
                <TextField
                  label="Meal name"
                  id="mealName"
                  type="text"
                  onChange={(e) => setName(e.target.value.toLowerCase())}
                  value={name}
                />
              </FormControl>

              <FormControl fullWidth style={{ marginBottom: "1.3rem" }}>
                <TextField
                  label="Price"
                  id="price"
                  type="number"
                  onChange={(e) => setPrice(Number(e.target.value))}
                  value={price}
                />
              </FormControl>
              <br />

              <select
                className="w-100 shadow-0 drop-down-select"
                style={{ paddingTop: "16px", paddingBottom: "16px" }}
                value={selectedCategory}
                onChange={(e) =>
                  setSelectedCategory(e.target.value.toLowerCase())
                }
              >
                <option value="">
                  {loading_categories && !error_fetching_categories
                    ? "Loading..."
                    : "Select Category"}
                </option>
                {/*  replace type any with categories type */}
                {categories?.map((item: any) => (
                  <option key={item?._id} value={item?.name.toLowerCase()}>
                    {item?.name}
                  </option>
                ))}
              </select>
              {/* {categories?.length === 0 && (
                <p style={{ fontSize: "13px", color: "red" }}>
                  Please add a category, as none currently exists.
                </p>
              )} */}
              <p style={{ fontSize: "13px", marginTop: "-25px" }}>
                <a href="/meal-categories" target="_blank">
                  How do I know a meal category
                </a>
              </p>

              <FormControl
                fullWidth
                style={{ marginTop: "1.3rem", marginBottom: "1.3rem" }}
              >
                <TextField
                  label="Description"
                  id="description"
                  className="text-area"
                  multiline
                  rows={5}
                  onChange={(e) => setDescription(e.target.value.toLowerCase())}
                  value={description}
                />
              </FormControl>

              <button className="w-100 save-button" type="submit">
                {creating_meal ? (
                  <Loader
                    height="27px"
                    width="27px"
                    borderTopColor="#FFAE0A"
                    borderTopWidth="5px"
                    borderWidth="5px"
                  />
                ) : (
                  "ADD ITEM"
                )}
              </button>
            </div>
          </form>
        </section>
      </MDBContainer>
    </PageLayout>
  );
};

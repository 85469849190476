/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from "react";
import { Provider, useDispatch } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { store } from "./app/store";
import { ToastContainer } from "react-toastify";
import { routes } from "./utils/data/routes";
// import { getMessaging, getToken }from "firebase/messaging";
// import io, { Socket } from 'socket.io-client';
import "./assets/css/globalStyle.scss";
import "./assets/css/restaurant-portal/sign-in.scss";
import "./assets/css/restaurant-portal/sign-up.scss";
import "./assets/css/restaurant-portal/dashboard.order-management.scss";
import "./assets/css/restaurant-portal/dashboard.profile.scss";
import "./assets/css/restaurant-portal/dashboard.profile.edit.scss";
import "./assets/css/restaurant-portal/dashboard.reviews.scss";
import "./assets/css/restaurant-portal/dashboard.menu-manager.scss";
import "./assets/css/restaurant-portal/dashboard.add-menu.scss";
import "./assets/css/restaurant-portal/dashboard.acct-activation.scss";
import "./assets/css/restaurant-portal/dashboard.notification.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
// import PushNotification from "./components/notificationComponent/pushNotification";
// import { useLazyGetRefreshTokenQuery } from "./features/services/restaurant/service";
import axios from "axios";
import { setUser } from "./features/slice/restaurant/authSlice";

const router = createBrowserRouter(routes);
// Login details to test restaurant
//mlh36239@vogco.com
//Pa$$w0rd!

function AppWrapper() {
  const rToken = sessionStorage.getItem("PEAKREACH_REFRESH");
  const dispatch = useDispatch();

  const refetchToken = () => {
    try {
      axios
        .get(
          `
        ${process.env.REACT_APP_BACKEND_BASE_URL}/restaurants/refresh-token`,
          { headers: { Authorization: `Bearer ${rToken}` } }
        )
        .then((res) => {
          dispatch(
            setUser({
              token: res.data.token,
              message: "token refetch successful",
            })
          );
        });
    } catch (err) {}
  };

  useEffect(() => {
    // Call the function immediately when the component mounts

    // Set up an interval to call the function every 1 hour (3600000 milliseconds)
    const intervalId = setInterval(() => {
      refetchToken();
    }, 3600000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  // Set interval to recall the function every 1 hour
  // const intervalId = setInterval(  , 3600000);

  // Optionally, you can clear the interval after a certain number of repetitions or based on some condition
  // For example, clear the interval after 24 hours (24 repetitions)
  // const maxRepetitions = 24;
  // let repetitions = 0;

  // function checkRepetitions() {
  //   repetitions++;
  //   if (repetitions === maxRepetitions) {
  //     clearInterval(intervalId);
  //   }
  // }

  // Set interval to check repetitions every 1 hour
  // setInterval(checkRepetitions, 3600000);

  // const regenerateToken = () => {
  //   // call refreshToken function here passing in the rToken
  //   //dispatch setUser
  // }

  return <RouterProvider router={router} />;
}

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <AppWrapper />
      </Provider>
      <ToastContainer hideProgressBar={true} position="top-right" />
      {/* <PushNotification /> */}
    </React.StrictMode>
  );
}

export default App;

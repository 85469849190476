import React, { useState, useEffect } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { NavLink } from "react-router-dom";
import { PageLayout } from "../../../components/restaurant-portal/PageLayout";
import { ProfilePropsInterface } from "../restaurant.config";
import { useLazyGetRestaurantProfileQuery } from "../../../features/services/restaurant/service";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import Skeleton from "react-loading-skeleton";

export const RestaurantProfile = () => {
  // const token = useSelector((state: RootState) => state?.restaurantAuth?.token);

  const [
    getRestaurantProfile,
    { data: profileDetails, isLoading, error, isSuccess },
  ] = useLazyGetRestaurantProfileQuery();

  useEffect(() => {
    getRestaurantProfile();
  }, []);

  return (
    <PageLayout>
      <MDBContainer fluid={true} breakpoint="md" className="profile-container">
        <div className="nav-link">
          <h1>Profile</h1>
          <NavLink to="/restaurant-portal/profile/edit" className="edit-button">
            EDIT PROFILE
          </NavLink>
        </div>
        <MDBRow>
          <MDBCol size={12} md={6} className="col1">
            {profileDetails?.image_url ? (
              <img
                src={profileDetails?.image_url}
                alt={""}
                width="90%"
                height="230px"
              />
            ) : (
              <Skeleton width="90%" height="230px" />
            )}
          </MDBCol>
          <MDBCol size={12} md={6} className="col2">
            <div>
              <h3>RESTAURANT NAME</h3>
              <p style={{ textTransform: "capitalize" }}>
                {profileDetails?.name || (
                  <Skeleton height={"30px"} width={"60%"} />
                )}
              </p>
            </div>
            <div>
              <h3>RESTAURANT ADDRESS</h3>
              <p>
                {profileDetails?.address?.address || (
                  <Skeleton height={"30px"} width={"60%"} />
                )}
              </p>
            </div>
            <div>
              <h3>CONTACT PHONE</h3>
              <p>
                {profileDetails?.phone || (
                  <Skeleton height={"30px"} width={"60%"} />
                )}
              </p>
            </div>
            <div>
              <h3>OPENING HOURS</h3>
              {profileDetails?.days_open?.map(
                (
                  item: { day: string; open: string; close: string },
                  i: number
                ) => (
                  <p key={i}>
                    <span style={{ textTransform: "capitalize" }}>
                      {item.day}
                    </span>
                    : {item.open}-{item.close}
                  </p>
                )
              ) || <Skeleton height={"30px"} width={"60%"} />}
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </PageLayout>
  );
};

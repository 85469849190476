import { useState } from "react";
import { MDBBtn, MDBCollapse, MDBContainer, MDBInput } from "mdb-react-ui-kit";
import { TfiAngleDown, TfiAngleUp } from "react-icons/tfi";

import { CustomToolTip, Stages } from "../../../components";
import { AccountActivationProps } from "../restaurant.config";

const BillingAndPayment = (props: AccountActivationProps) => {
  const { index, steps, setIndex, formData, setFormData } = props;
  const [toggle, setToggle] = useState({
    billing: false,
    payment: false,
  });

  const handleToggle = (tag: string) => {
    switch (tag) {
      case "billing":
        setToggle((prevState) => {
          return {
            ...prevState,
            billing: !toggle.billing,
          };
        });
        break;
      case "payment":
        setToggle((prevState) => {
          return {
            ...prevState,
            payment: !toggle.payment,
          };
        });
        break;

      default:
        break;
    }
  };

  const handleSubmit = () => {
    const { tax_id, billing_info, account_details, business_Legal_name } =
      formData;
    if (
      tax_id === "" ||
      billing_info.region === "" ||
      account_details.account_name === "" ||
      account_details.account_number === "" ||
      account_details.bank === "" ||
      billing_info.billing_address === "" ||
      business_Legal_name === ""
    ) {
      return;
    } else {
      setIndex((prevState) => prevState + 1);
    }
  };

  return (
    <div>
      <MDBContainer className="account-activation">
        <h1>Account Activation</h1>
        <Stages steps={steps} step={index} />
        <form onSubmit={handleSubmit} className="business-information">
          <div className="biz-cont">
            <h4>
              Business Information &nbsp;
              <CustomToolTip
                message="All fields marked with * are required"
                color="#FFAE0A"
                size={15}
              />
            </h4>
            <div>
              <label htmlFor="tin">
                Tax Identity No. (TIN)/National Identification Number (NIN)
                <span className="required-text">*</span>
              </label>
              <br />
              <MDBInput
                id="tin"
                tabIndex={1}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    tax_id: e.target.value.toLowerCase(),
                  })
                }
                type="text"
                required
              />
            </div>
            <div className="mt-2">
              <label htmlFor="b_name">
                Name of Legal Entity<span className="required-text">*</span>
              </label>
              <br />
              <MDBInput
                id="b_name"
                type="text"
                tabIndex={2}
                value={formData.business_Legal_name}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    business_Legal_name: e.target.value.toLowerCase(),
                  })
                }
                required
              />
            </div>
          </div>

          {/* billing section  */}
          <div className="bill-cont">
            <div
              className="collapse-btn"
              onClick={() => handleToggle("billing")}
            >
              <h4>
                Billing Information &nbsp;
                <CustomToolTip
                  message="All fields marked with * are required"
                  color="#FFAE0A"
                  size={15}
                />
              </h4>
              {toggle.billing ? (
                <h4>
                  <TfiAngleUp />
                </h4>
              ) : (
                <h4>
                  <TfiAngleDown />
                </h4>
              )}
            </div>
            <MDBCollapse show={toggle.billing}>
              <div className="mt-2">
                <label htmlFor="b_address">
                  Billing Address<span className="required-text">*</span>
                </label>
                <br />
                <MDBInput
                  id="b_address"
                  type="text"
                  tabIndex={3}
                  value={formData.billing_info.billing_address}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      billing_info: {
                        ...formData.billing_info,
                        billing_address: e.target.value.toLowerCase(),
                      },
                    })
                  }
                  required
                />
              </div>
              <div className="mt-2">
                <label htmlFor="b_region">
                  State/Region<span className="required-text">*</span>
                </label>
                <br />
                <MDBInput
                  id="b_region"
                  type="text"
                  tabIndex={4}
                  value={formData.billing_info.region}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      billing_info: {
                        ...formData.billing_info,
                        region: e.target.value.toLowerCase(),
                      },
                    })
                  }
                  required
                />
              </div>
            </MDBCollapse>
          </div>

          {/* Payment section  */}
          <div className="paym-cont">
            <div
              className="collapse-btn"
              onClick={() => handleToggle("payment")}
            >
              <h4>
                Payment Details &nbsp;
                <CustomToolTip
                  message="All fields marked * are required"
                  color="#FFAE0A"
                  size={15}
                />
              </h4>
              {toggle.payment ? (
                <h4>
                  <TfiAngleUp />
                </h4>
              ) : (
                <h4>
                  <TfiAngleDown />
                </h4>
              )}
            </div>
            <MDBCollapse show={toggle.payment}>
              <div className="mt-2">
                <label htmlFor="bank_name">
                  Name of Bank<span className="required-text">*</span>
                </label>
                <br />
                <MDBInput
                  id="bank_name"
                  type="text"
                  tabIndex={5}
                  value={formData.account_details.bank}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      account_details: {
                        ...formData.account_details,
                        bank: e.target.value.toLowerCase(),
                      },
                    })
                  }
                  required
                />
              </div>
              <div className="mt-2">
                <label htmlFor="acct_no">
                  Account Number<span className="required-text">*</span>
                </label>
                <br />
                <MDBInput
                  id="acct_no"
                  type="text"
                  tabIndex={6}
                  maxLength={10}
                  value={formData.account_details.account_number}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      account_details: {
                        ...formData.account_details,
                        account_number: e.target.value,
                      },
                    })
                  }
                  required
                />
              </div>
              <div className="mt-2">
                <label htmlFor="b_add">
                  Account Name<span className="required-text">*</span>
                </label>
                <br />
                <MDBInput
                  id="acct_name"
                  type="text"
                  tabIndex={7}
                  minLength={3}
                  value={formData.account_details.account_name}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      account_details: {
                        ...formData.account_details,
                        account_name: e.target.value.toLowerCase(),
                      },
                    })
                  }
                  required
                />
              </div>
            </MDBCollapse>
          </div>

          <div className="form-btns">
            <MDBBtn className="back-btn" onClick={() => setIndex(index - 1)}>
              &larr; Back
            </MDBBtn>
            <MDBBtn className="proceed-btn" type="submit">
              Proceed &rarr;
            </MDBBtn>
          </div>
        </form>
      </MDBContainer>
    </div>
  );
};

export default BillingAndPayment;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { AuthProps } from "../../config";

const initialState: AuthProps = {
  token: localStorage.getItem("PEAKREACH_TOKEN") || null,
  isAuth: localStorage.getItem("PEAKREACH_TOKEN") ? true : false,
  message: "",
  userInfo: null,
};

export const authSlice = createSlice({
  name: "restaurantAuth",
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{ message: string; token: string }>
    ) => {
      // I left this to be sure I'm not breaking anything just incase it's been used somewhere
      localStorage.setItem(
        "restaurant",
        JSON.stringify({
          message: action.payload.message,
          token: action.payload.token,
        })
      );

      // I need this to set The authorization token
      localStorage.setItem("PEAKREACH_TOKEN", action.payload.token);
      state.message = action.payload.message;
      state.isAuth = true;
      state.token = action.payload.token;
      // state.token = action.payload.token;  // this should be removed as it only sets the auth token only on login
      // state.token = localStorage.getItem("token"); // this solves the issue
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
    removeUser: (state) => {
      localStorage.clear();
      state.message = null;
      state.token = null;
      state.isAuth = false;
      state.userInfo = null;
    },
  },
});

// export const selectRestaurantAuth = (state: RootState) => state.restaurantAuth;

export const { setUser, removeUser, setUserInfo } = authSlice.actions;

export default authSlice.reducer;

import {
  currencyFormatter,
  forMatDate,
  formatDateTime,
  truncateText,
} from "../../utils/methods/methods";
import Skeleton from "react-loading-skeleton";

type OrderCardProps = {
  id?: string | any;
  time?: string | any;
  amount?: number | any;
  headerClass?: string | any;
  borderColor: string;
  handleClick: React.MouseEventHandler<HTMLLIElement>;
};

export const OrderCard = ({
  id,
  time,
  amount,
  headerClass,
  borderColor,
  handleClick,
}: OrderCardProps) => {
  return (
    <>
      <li key={id} style={{ borderColor }} onClick={handleClick}>
        <div className={headerClass}>
          <h3>Order ID: {truncateText(id, 10)} </h3>
          <p style={{ fontSize: "14px", color: "#ababab" }}>
            {forMatDate(time)}
          </p>
        </div>
        <div style={{ display: "grid", rowGap: "7px" }}>
          <div>
            {amount?.currency_code === "NGN" ? "₦" : "USD"}
            {currencyFormatter(amount.amount)}
          </div>
          <p style={{ fontSize: "13px", color: "#ababab" }}>
            {formatDateTime(time)}
          </p>
        </div>
      </li>
    </>
  );
};

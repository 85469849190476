import { useState } from "react";
import ContactInformation from "./AccountContactInfo";
import BillingAndPayment from "./AccountBillingInfo";
import ContractSigning from "./AccountContract";
import { UserActivationDataProps } from "../restaurant.config";
import ActivationCode from "./ActivationCode";

const steps = [
  { label: "Contact Information" },
  { label: "Business Details" },
  { label: "Contract Signing" },
  { label: "Activation Code" },
];

const initial_data: UserActivationDataProps = {
  email: "",
  // phone: '',
  tax_id: "",
  business_Legal_name: "",
  billing_info: {
    billing_address: "",
    region: "",
  },
  account_details: {
    bank: "",
    account_number: "",
    account_name: "",
  },
  social_links: {
    facebook: "",
    instagram: "",
    X: "",
    // linkedIn: ''
  },
  mail_contract_to: "",
  activation_code: null,
  signatory: "",
};

export const AccountActivation = () => {
  const [index, setIndex] = useState(0);
  const [formData, setFormData] = useState(initial_data);

  const props = { index, setIndex, steps, formData, setFormData };

  return (
    <div className="account-activation-overlay">
      {index === 0 && <ContactInformation {...props} />}

      {index === 1 && <BillingAndPayment {...props} />}

      {index === 2 && <ContractSigning {...props} />}

      {index === 3 && <ActivationCode {...props} />}
    </div>
  );
};

import { Tooltip } from "@mui/material"
import { RiQuestionFill } from "react-icons/ri";
import IconButton from '@mui/material/IconButton';

type ToolTipProps = {
    message: string,
    placement?: "top" | "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start" | undefined,
    color: string,
    size: number,
}


export const CustomToolTip = ({ message, color, size, placement='top-end' }: ToolTipProps) => {
    return(
         <Tooltip title={message} placement={placement}>    
            <IconButton> 
                <RiQuestionFill 
                    color={color} 
                    size={size} 
                    />
            </IconButton>   
        </Tooltip>
    )
}
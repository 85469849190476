import { reviews } from "../../utils/data/landingPage";
import LandingPageLayout from "../../components/landing-page/LandingPageLayout";
import {
  AboutUs,
  ContactUs,
  FooterSection,
  Jumbotron,
  Reviews,
  Service,
} from "./components";
import FAQ from "./components/FAQ";

export const LandingPage = () => {
  return (
    <LandingPageLayout>
      <Jumbotron />
      <AboutUs />
      <Service />
      <Reviews reviews={reviews} />
      <FAQ />

      <ContactUs />
      <FooterSection />
    </LandingPageLayout>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
} from "mdb-react-ui-kit";
import { MenuCard } from "../../../components/restaurant-portal/MenuCard";
import { PageLayout } from "../../../components/restaurant-portal/PageLayout";
import { Link } from "react-router-dom";
import {
  useCreateMealCategoryMutation,
  useDeleteMealMutation,
  useGetMealsCategoriesQuery,
  useLazyGetMealsQuery,
  useUpdateMealMutation,
} from "../../../features/services/restaurant/service";
import { toast } from "react-toastify";
import Loader from "../../../components/loader";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FormControl, TextField } from "@mui/material";

export const RestaurantMenuManager = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [page, setPage] = useState<number>(1);
  const [meal_category, setMealCategory] = useState<any>("");
  const [category_edit_modal, setCategoryEditModal] = useState<boolean>(false);

  //  Mutations and Queries

  const [createMealCategory, { data: response, isLoading: adding_category }] =
    useCreateMealCategoryMutation();

  const [
    getMeals,
    { data: meals, isLoading: loading_meals, error: error_fetching_meals },
  ] = useLazyGetMealsQuery();

  const [
    deleteMeal,
    {
      data: delete_meal_response,
      isLoading: deleting_meal,
      isSuccess: delete_successful,
      isError: delete_failed,
    },
  ] = useDeleteMealMutation();

  const {
    data: categories,
    isLoading: loading_categories,
    error: error_fetching_categories,
  } = useGetMealsCategoriesQuery();
  const [
    updateMeal,
    {
      data: updatedMeal,
      isLoading: updatingMeal,
      isSuccess: mealUpdateSuccess,
      error,
    },
  ] = useUpdateMealMutation();

  // Functions
  const toggleModal = (e: any) => {
    e.preventDefault();
    setShowModal(true);
  };
  const handleMealsInstock = (in_stock: boolean, meal_id: string) => {
    updateMeal({
      meal_id,
      body: {
        is_in_stock: `${in_stock}`,
      },
    });
  };
  const handleCloseEditModal = (event: any) => {
    event.stopPropagation();
    setCategoryEditModal(false);
  };
  const fetchMeals = () => {
    // if (categories && meal_category) {
    // }
    getMeals({ page, meal_category });
    if (error_fetching_categories) {
      toast.error("Error fetching meals categories");
    }
  };
  // useEffects
  // useEffect(() => {
  //   // categories && setMealCategory(categories[0]?.name);
  //   setMealCategory("");
  // }, [categories, loading_categories]);

  useEffect(() => {
    fetchMeals();
  }, [page, meal_category]);

  //
  useEffect(() => {
    if (updatedMeal) {
      fetchMeals();
      toast.success("Meal status updated successfully");
    } else if (error) {
      toast.error("Error updating meal status");
    }
  }, [updatedMeal, error]);

  useEffect(() => {
    if (delete_successful) {
      toast.success("Meal deleted successful!");
      setShowDeleteModal(false);
      getMeals({ page, meal_category });
    }
    if (delete_failed) {
      toast.error("failed to delete meal, try again");
      setShowDeleteModal(false);
    }
  }, [delete_failed, delete_successful]);

  const handleMenuDelete = (id: string, name: string) => {
    setItemToBeDeleted({ id, name });
    setShowDeleteModal(true);
  };

  const handleDeleteCancel = () => {
    setItemToBeDeleted(null);
    setShowDeleteModal(false);
  };

  const handleDeleteProceed = () => {
    try {
      deleteMeal(itemToBeDeleted?.id);
    } catch (err) {
      toast.error(`failed to delete ${itemToBeDeleted?.name}`);
      setShowDeleteModal(false);
    }
  };

  // AddCategory component
  const AddCategoryModal = () => {
    const [category, setCategory] = useState<string>("");

    const reset = () => {
      setCategory("");
      setShowModal(false);
    };

    const handleToggleShow = (e: any) => {
      e.preventDefault();
      reset();
    };

    const handleAddCategory = async () => {
      try {
        if (category) {
          const response = await createMealCategory({
            name: category.toLowerCase(),
          }).unwrap();
          toast.success(`${category} category added successfully`);
          setCategory("");
          setShowModal(false);
        } else {
          toast.error("Please enter category name");
        }
        // reset();
      } catch (err: any) {
        toast.error(err?.data?.error);
      }
    };

    return (
      <MDBModal
        show={showModal}
        setShow={setShowModal}
        backdrop={false}
        tabIndex="-1"
        className="modal"
      >
        <MDBModalDialog className="modal-dialog">
          <MDBModalContent className="modal-container">
            <MDBModalBody className="modal-body">
              <h1>Add Category</h1>
              <FormControl fullWidth style={{ marginTop: "1rem" }}>
                <TextField
                  label="Category name"
                  onChange={(e) => setCategory(e.target.value)}
                  value={category}
                />
              </FormControl>

              <div className="buttons">
                <MDBBtn
                  className="w-25 btn btn-add-category"
                  onClick={handleAddCategory}
                >
                  {adding_category ? (
                    <Loader
                      height="27px"
                      width="27px"
                      borderTopColor="#FFAE0A"
                      borderTopWidth="5px"
                      borderWidth="5px"
                    />
                  ) : (
                    "ADD"
                  )}
                </MDBBtn>
                <MDBBtn
                  className="w-25 btn btn-default"
                  color="none"
                  onClick={(e) => handleToggleShow(e)}
                >
                  CANCEL
                </MDBBtn>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
  };
  return (
    <>
      <PageLayout>
        <MDBContainer
          fluid={true}
          breakpoint="md"
          className="menu-manager-container"
        >
          <h2>Menu manager</h2>
          <div className="add-btns-container">
            <Link
              to="/restaurant-portal/menu-manager/add-item"
              className="btn add-item"
            >
              ADD NEW MEAL
            </Link>
            {/* <button className="btn add-category" onClick={(e) => toggleModal(e)}>
              ADD MEAL CATEGORY
            </button> */}
          </div>

          <div className="menu-category-container">
            <div>
              {/* <h4>{item.category.toUpperCase()}</h4> */}
              <div
                className="categories-tab"
                style={{
                  display: "flex",
                  columnGap: "50px",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                {categories?.length > 0 && (
                  <select
                    className="meal-category-dropdown"
                    value={meal_category}
                    onChange={(e) =>
                      setMealCategory(e.target.value.toLowerCase())
                    }
                  >
                    <option value="">All meals</option>
                    {categories?.map((item: any) => (
                      <option key={item?._id} value={item?.name.toLowerCase()}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                )}

                {/* <button 
                    className="btn edit-category"
                    onClick={() => setCategoryEditModal(true)}>
                    VIEW ALL CATEGORY
                  </button> */}
              </div>
              <div>
                <h4 style={{ textTransform: "uppercase" }}>{meal_category}</h4>
              </div>
              {loading_meals ? (
                <SkeletonTheme highlightColor="#f0f0f0">
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton
                      width={400}
                      height={120}
                      style={{ marginRight: "30px" }}
                    />
                    <Skeleton
                      width={400}
                      height={120}
                      style={{ marginRight: "30px" }}
                    />
                    <Skeleton width={400} height={120} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "35px",
                    }}
                  >
                    <Skeleton
                      width={400}
                      height={120}
                      style={{ marginRight: "30px" }}
                    />
                    <Skeleton
                      width={400}
                      height={120}
                      style={{ marginRight: "30px" }}
                    />
                    <Skeleton width={400} height={120} />
                  </div>
                </SkeletonTheme>
              ) : (
                <div className="menu-container">
                  {meals?.length > 0 ? (
                    meals?.map((menu: any) => (
                      <MenuCard
                        key={menu?._id}
                        id={menu?._id}
                        image={menu?.image_url}
                        name={menu?.name}
                        price={menu?.price?.amount}
                        desc={menu?.description}
                        is_in_stock={menu?.is_in_stock}
                        updatingMeal={updatingMeal}
                        handleStockStatus={() =>
                          menu?.is_in_stock
                            ? handleMealsInstock(false, menu?._id)
                            : handleMealsInstock(true, menu?._id)
                        }
                        onClick={() => handleMenuDelete(menu?._id, menu?.name)}
                      />
                    ))
                  ) : (
                    <p>
                      No meals in this category
                      {/* <button
                        style={{
                          padding: "5px 20px",
                          marginLeft: "20px",
                          background: "#FFAE0A",
                          border: "none",
                          borderRadius: "3px",
                          color: "white",
                          marginBottom: "30px",
                        }}
                      >
                        Add meal
                      </button> */}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </MDBContainer>
        <AddCategoryModal />
      </PageLayout>
      {category_edit_modal && (
        <div
          className="category-edit-modal"
          onClick={handleCloseEditModal}
        ></div>
      )}
      {showDeleteModal && (
        <div
          className="category-edit-modal"
          onClick={() => setShowDeleteModal(false)}
        >
          <div className="delete-modal">
            <p>
              Are you sure you want to delete menu:{" "}
              <b>{itemToBeDeleted?.name}</b>?
            </p>
            {deleting_meal ? (
              <p>Deleting meal...</p>
            ) : (
              <div className="btn-containers">
                <button className="proceed-btn" onClick={handleDeleteProceed}>
                  Proceed
                </button>
                <button className="cancel-btn" onClick={handleDeleteCancel}>
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

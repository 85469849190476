/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StarRatings from "react-star-ratings";
import {
  MDBCol,
  MDBContainer,
  MDBProgress,
  MDBProgressBar,
  MDBRow,
} from "mdb-react-ui-kit";
import { PageLayout } from "../../../components/restaurant-portal/PageLayout";
import Skeleton from "react-loading-skeleton";
import { getDateFormat } from "../../../utils/methods/methods";
import { RootState } from "../../../app/store";
import { useLazyGetRestaurantProfileQuery } from "../../../features/services/restaurant/service";

interface ReviewProps {
  id: string;
  user: {
    first_name: string;
    last_name: string;
  };
  rating: number;
  review: string;
  created_at: Date;
}

interface ReviewStats {
  rating: number;
  rating_sum: number;
  total_rating: number;
}

interface Reviews {
  reviews: ReviewProps[];
  reviews_stats: ReviewStats[];
}

export const RestaurantReview = () => {
  const [reviews, setReviews] = useState<Reviews | null>(null);
  const [page, setPage] = useState<number>(1);
  const { token, userInfo } = useSelector(
    (state: RootState) => state.restaurantAuth
  );

  const [
    getRestaurantProfile,
    { data: profileDetails, isLoading, error, isSuccess },
  ] = useLazyGetRestaurantProfileQuery();
  const getRestaurantReviews = async (restaurant_id: string) => {
    try {
      const response = await fetch(
        `https://api.peakreachdelivery.com/api/v1/reviews/restaurant-reviews?review_for_id=${restaurant_id}&page=${page}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const res = await response.json();
        setReviews(res);
      }
    } catch (error) {
      console.error("Couldn't get reviews", error);
    }
  };
  useEffect(() => {
    getRestaurantProfile();
  }, []);

  useEffect(() => {
    if (profileDetails) {
      // getRestaurantReviews("6644c5aef43a53810ebab6ab");
      getRestaurantReviews(profileDetails?._id);
    }
  }, [page, profileDetails]);

  const calculateAverageRating = (reviews: ReviewProps[]) => {
    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    return totalRating / reviews.length;
  };

  const filterReviewsByRating = (reviews: ReviewProps[], rating: number) => {
    return reviews.filter((review) => review.rating === rating);
  };

  const renderProgressBars = (reviews: ReviewProps[]) => {
    const ratingCounts = [5, 4, 3, 2, 1].map((rating) => {
      const count = filterReviewsByRating(reviews, rating).length;
      return { rating, count };
    });

    return ratingCounts.map(({ rating, count }) => (
      <div className="bar-cont" key={rating}>
        <div>{rating}</div>
        <MDBProgress height={7}>
          <MDBProgressBar
            striped
            bgColor="success"
            width={(count / reviews.length) * 100}
            valuemin={0}
            valuemax={100}
          />
        </MDBProgress>
      </div>
    ));
  };

  return (
    <PageLayout>
      <MDBContainer
        fluid={true}
        breakpoint="md"
        className="reviews-container-restaurant"
      >
        <h1>Reviews</h1>
        {reviews ? (
          reviews.reviews.length > 0 ? (
            <MDBRow className="review-row">
              <MDBCol size={12} md={8}>
                <div className="reviews">
                  <ul>
                    {reviews.reviews.map((review: ReviewProps, id: number) => (
                      <li key={id}>
                        <h3>
                          {`${review.user.first_name} ${review.user.last_name}` || (
                            <Skeleton width="150px" />
                          )}
                        </h3>
                        <div className="rating-comment-container">
                          <StarRatings
                            starRatedColor="#FFAE0A"
                            numberOfStars={5}
                            name="rating"
                            rating={review.rating}
                            starDimension="22px"
                            starSpacing="5px"
                          />
                          <div className="comment">
                            {/* <p>{review.review || <Skeleton width="200px" />}</p> */}
                          </div>
                        </div>
                        <p className="date">
                          {getDateFormat(review.created_at) || (
                            <Skeleton width="180px" />
                          )}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </MDBCol>
              <MDBCol size={12} md={4}>
                <div className="total-review-ratings">
                  <div className="total-reviews">
                    <h2>Total reviews</h2>
                    <p>{reviews.reviews.length || <Skeleton width="70px" />}</p>
                  </div>
                  <div className="average-rating">
                    <h2>Average rating</h2>
                    <div className="ratings-cont">
                      <span className="av-rating">
                        {Math.round(
                          calculateAverageRating(reviews.reviews) * 10
                        ) / 10}
                        &nbsp;
                      </span>
                      <span>
                        <StarRatings
                          starRatedColor="#FFAE0A"
                          numberOfStars={5}
                          name="rating"
                          rating={calculateAverageRating(reviews.reviews)}
                          starDimension="27px"
                          starSpacing="5px"
                        />
                      </span>
                    </div>
                    <div className="progress-bars">
                      {renderProgressBars(reviews.reviews)}
                    </div>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          ) : (
            <div>You have no reviews yet</div>
          )
        ) : (
          <div>Loading...</div>
        )}
      </MDBContainer>
    </PageLayout>
  );
};

// Initial codes just for refrenece

// import StarRatings from "react-star-ratings";
// import {
//   MDBCol,
//   MDBContainer,
//   MDBProgress,
//   MDBProgressBar,
//   MDBRow,
// } from "mdb-react-ui-kit";
// import { PageLayout } from "../../../components/restaurant-portal/PageLayout";
// import Skeleton from "react-loading-skeleton";
// import { getDateFormat } from "../../../utils/methods/methods";
// import {
//   useLazyGetRestaurantProfileQuery,
//   useLazyGetRestaurantReviewsQuery,
// } from "../../../features/services/restaurant/service";
// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { RootState } from "../../../app/store";

// interface ReviewProps {
//   id: string;
//   user: {
//     first_name: string;
//     last_name: string;
//   };
//   rating: number;
//   review: string;
//   created_at: Date;
// }
// interface ReviewStats {
//   rating: number;
//   rating_sum: number;
//   total_rating: number;
// }
// interface Reviews {
//   reviews: ReviewProps[];
//   reviews_stats: ReviewStats[];
// }

// export const RestaurantReview = () => {
//   const [reviews, setReviews] = useState<any | []>([]);
//   const [page, setPage] = useState<number>(1);
//   const { token, userInfo } = useSelector(
//     (state: RootState) => state.restaurantAuth
//   );
//   const getRestaurantReviews = async (restaurant_id: string) => {
//     try {
//       const response = await fetch(
//         `https://api.peakreachdelivery.com/api/v1/reviews/restaurant-reviews?review_for_id=${restaurant_id}&page=${page}`,
//         {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       if (response.status === 200) {
//         const res = await response.json();
//         setReviews(res);
//       }
//     } catch (error) {
//       console.error("couldn't get reviews", error);
//     }
//   };

//   // const [getRestaurantReviews,
//   //     { data: reviewsData,
//   //       error: review_error,
//   //       isSuccess: review_success
//   //     }
//   //   ] = useLazyGetRestaurantReviewsQuery();

//   //////// Get Restaurant Information

//   ///////   Get Restaurant Reviews
//   useEffect(() => {
//     if (userInfo) {
//       //   getRestaurantReviews(userInfo?._id);
//     }
//     getRestaurantReviews("6644c5aef43a53810ebab6ab");
//   }, [page]);
//   // const [ getRestaurantReviews, {
//   //     data: reviews,
//   //     isSuccess: reviews_success,
//   //     isError: reviews_error
//   // }] = useLazyGetRestaurantReviewsQuery();

//   // useEffect(() => {
//   //     getRestaurantReviews()
//   // }, [])

//   let userRating = 0;

//   for (let i = 0; i < reviews?.reviews?.length; i++) {
//     userRating += reviews[i]?.rating;
//   }
//   const averageRating = userRating / reviews.length;

//   const progress_5 = reviews?.reviews?.filter(
//     (review: ReviewProps) => review?.rating === 5
//   );
//   const progress_4 = reviews?.reviews?.filter(
//     (review: ReviewProps) => review?.rating === 4
//   );
//   const progress_3 = reviews?.reviews?.filter(
//     (review: ReviewProps) => review?.rating === 3
//   );
//   const progress_2 = reviews?.reviews?.filter(
//     (review: ReviewProps) => review?.rating === 2
//   );
//   const progress_1 = reviews?.reviews?.filter(
//     (review: ReviewProps) => review?.rating === 1
//   );

//   return (
//     <PageLayout>
//       <MDBContainer
//         fluid={true}
//         breakpoint="md"
//         className="reviews-container-restaurant"
//       >
//         <h1>Reviews</h1>
//         {reviews?.reviews?.length > 0 ? (
//           <MDBRow className="review-row">
//             <MDBCol size={12} md={8}>
//               <div className="reviews">
//                 <ul>
//                   {reviews?.reviews?.map((review: ReviewProps, id: number) => (
//                     <li key={id}>
//                       <h3>
//                         {`${review?.user?.first_name} ${review?.user?.last_name}` || (
//                           <Skeleton width={"150px"} />
//                         )}
//                       </h3>
//                       <div className="rating-comment-container">
//                         <StarRatings
//                           starRatedColor="#FFAE0A"
//                           numberOfStars={5}
//                           name="rating"
//                           rating={review?.rating}
//                           starDimension="22px"
//                           starSpacing="5px"
//                         />
//                         <div className="comment">
//                           {/* <p>{review?.review || <Skeleton width={"200px"} />}</p> */}
//                         </div>
//                       </div>
//                       <p className="date">
//                         {getDateFormat(review?.created_at) || (
//                           <Skeleton width={"180px"} />
//                         )}
//                       </p>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//             </MDBCol>
//             <MDBCol size={12} md={4}>
//               <div className="total-review-ratings">
//                 <div className="total-reviews">
//                   <h2>Total reviews</h2>
//                   <p>{reviews?.length || <Skeleton width={"70px"} />}</p>
//                 </div>
//                 <div className="average-rating">
//                   <h2>Average rating</h2>
//                   <div className="ratings-cont">
//                     <span className="av-rating">
//                       {Math.round(averageRating * 10) / 10}&nbsp;
//                     </span>
//                     <span>
//                       <StarRatings
//                         starRatedColor="#FFAE0A"
//                         numberOfStars={5}
//                         name="rating"
//                         rating={averageRating}
//                         starDimension="27px"
//                         starSpacing="5px"
//                       />
//                     </span>
//                   </div>
//                   <div className="progress-bars">
//                     <div className="bar-cont">
//                       <div>5</div>
//                       <MDBProgress height={7}>
//                         <MDBProgressBar
//                           striped
//                           bgColor="success"
//                           width={progress_5?.length * 20}
//                           valuemin={0}
//                           valuemax={5}
//                         />
//                       </MDBProgress>
//                     </div>
//                     <div className="bar-cont">
//                       <div>4</div>
//                       <MDBProgress height={7}>
//                         <MDBProgressBar
//                           striped
//                           bgColor="success"
//                           width={progress_4?.length * 20}
//                           valuemin={0}
//                           valuemax={5}
//                         />
//                       </MDBProgress>
//                     </div>
//                     <div className="bar-cont">
//                       <div>3</div>
//                       <MDBProgress height={7}>
//                         <MDBProgressBar
//                           striped
//                           bgColor="success"
//                           width={progress_3?.length * 20}
//                           valuemin={0}
//                           valuemax={5}
//                         />
//                       </MDBProgress>
//                     </div>
//                     <div className="bar-cont">
//                       <div>2</div>
//                       <MDBProgress height={7}>
//                         <MDBProgressBar
//                           striped
//                           bgColor="success"
//                           width={progress_2?.length * 20}
//                           valuemin={0}
//                           valuemax={5}
//                         />
//                       </MDBProgress>
//                     </div>
//                     <div className="bar-cont">
//                       <div>1</div>
//                       <MDBProgress height={7}>
//                         <MDBProgressBar
//                           striped
//                           bgColor="success"
//                           width={progress_1?.length * 20}
//                           valuemin={0}
//                           valuemax={5}
//                         />
//                       </MDBProgress>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </MDBCol>
//           </MDBRow>
//         ) : (
//           <div>You have no reviews yet</div>
//         )}
//       </MDBContainer>
//     </PageLayout>
//   );
// };

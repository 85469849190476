import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { useSelector } from "react-redux";
// import { selectRestaurantAuth } from "../features/slice/restaurant/authSlice";
// import { Loader } from "./components/Loader";
// import { LandingPage } from "./pages";

// display loader
// const TestPage = React.lazy(() =>
//   Promise.all([
//     <import("./pages/landing-page/LandingPage")>,
//     new Promise((resolve) => setTimeout(resolve, 3000)),
//   ]).then(([moduleExports]) => moduleExports)
// );

type RouteProps = {
  redirectPath: string;
  children: any;
};

const ProtectedRoute = ({ redirectPath, children }: RouteProps) => {
  // const res = localStorage.getItem('token')
  let isAuth = localStorage.getItem("PEAKREACH_TOKEN");
  // let isAuth = true;

  // keep user logged in, if token exists
  if (!isAuth) {
    toast.error("Access denied, login required!");
    return <Navigate to={redirectPath} replace />;
  } else {
    return <div>{children}</div>;
  }
};

export default ProtectedRoute;

import { MDBContainer } from "mdb-react-ui-kit";
import { PageLayout } from "../../../components/restaurant-portal/PageLayout";
import { useState } from "react";
import { forMatDate, truncateText } from "../../../utils/methods/methods";
import EmptyNotification from "../../../assets/images/restaurant/dashboard/empty-notification.svg";
import { Close } from "@mui/icons-material";

const data = [
  {
    title: "Hello Vico",
    body: "you have a new order, check your dashboard for more information",
    icon: "https://res.cloudinary.com/sophdev/image/upload/v1672416214/file-upload/tmp-1-1672416212087.jpg",
    data: {
      restaurant_id: "",
      time: "0001-01-01T00:00:00Z",
      userId: "00000000000000000000",
    },
  },
  // {
  //     title: 'Hello Vico',
  //     body: 'you have a new order, check your dashboard for more information. you have a new order, check your dashboard for more information',
  //     icon: 'https://res.cloudinary.com/sophdev/image/upload/v1672416214/file-upload/tmp-1-1672416212087.jpg',
  //     data: {
  //         restaurant_id: '',
  //         time: '0001-01-01T00:00:00Z',
  //         userId: '00000000000000000000'
  //     }
  // },
  // {
  //     title: 'Hello Vico',
  //     body: 'you have a new order, check your dashboard for more information',
  //     icon: 'https://res.cloudinary.com/sophdev/image/upload/v1672416214/file-upload/tmp-1-1672416212087.jpg',
  //     data: {
  //         restaurant_id: '',
  //         time: '0001-01-01T00:00:00Z',
  //         userId: '00000000000000000000'
  //     }
  // },
  // {
  //     title: 'Hello Vico',
  //     body: 'you have a new order, check your dashboard for more information. you have a new order, check your dashboard for more information',
  //     icon: 'https://res.cloudinary.com/sophdev/image/upload/v1672416214/file-upload/tmp-1-1672416212087.jpg',
  //     data: {
  //         restaurant_id: '',
  //         time: '0001-01-01T00:00:00Z',
  //         userId: '00000000000000000000'
  //     }
  // },
];

interface NotificationProps {
  title: string;
  body: string;
  icon: string;
  data: {
    restaurant_id: string;
    time: string;
    userId: string;
  };
}

export const RestaurantNotification = () => {
  const [notifications, setNotifications] = useState<NotificationProps[] | []>(
    data
  );
  const [notification_detail, setNotificationDetail] =
    useState<NotificationProps | null>(null);
  const [mobileViewDisplay, setMobileViewDisplay] = useState(false);

  const handleNotificationClick = (data: NotificationProps) => {
    if (data) {
      setNotificationDetail(data);
      setMobileViewDisplay(true);
    }
  };

  return (
    <PageLayout>
      <MDBContainer
        fluid={true}
        breakpoint="md"
        className="notification-container"
      >
        <h2>Notifications</h2>
        {notifications.length > 0 ? (
          <div className="container-body">
            <div className="notification-list">
              <ul>
                {notifications.map((notification, index) => (
                  <li
                    key={index}
                    onClick={() => handleNotificationClick(notification)}
                  >
                    <div className="icon">
                      <img src={notification?.icon} alt={notification.title} />
                    </div>
                    <div className="text">
                      <p className="title">{notification.title}</p>
                      <p className="body">
                        {truncateText(notification.body, 100)}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {notification_detail && (
              <div className="notification-detail">
                <p className="date">
                  {forMatDate(notification_detail.data.time)}
                </p>
                <p className="detail-title">{notification_detail.title}</p>
                <p className="detail-body">{notification_detail.body}</p>
              </div>
            )}
          </div>
        ) : (
          <div className="empty-notification">
            <img src={EmptyNotification} />
            <p>No Notifications yet</p>
          </div>
        )}
      </MDBContainer>
      {mobileViewDisplay && notification_detail && (
        <div className="detail-overlay">
          <div className="detail-container">
            <Close
              className="close"
              onClick={() => setMobileViewDisplay(false)}
            />

            <div className="detail">
              <p className="date">
                {forMatDate(notification_detail.data.time)}
              </p>
              <p className="detail-title">{notification_detail.title}</p>
              <p className="detail-body">{notification_detail.body}</p>
            </div>
          </div>
        </div>
      )}
    </PageLayout>
  );
};
